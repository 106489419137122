<template>
  <SideLayout>
    <template #topTitle>
      <div class="topHeader">
        <label>工程机械列表</label>

        <el-button v-if="groupIds.length"
          type="text"
          @click.stop="clearAllGroup">取消所有</el-button>
      </div>
    </template>

    <template #top>
      <!-- 车组区域 -->
      <GroupTree ref="treeRef"
        defaultExpandAll
        showCheckbox :isCustomization="isCustomization"
        useDefault
        :checkStrictly="false"
        @checkChange="onCheckGroup"></GroupTree>
    </template>
    <template #bottom>
      <!-- 车辆列表区域 -->
      <VehicleList ref="vehicleRef"
        :showChannel="false"
        :groupIds="groupIds"
        :onlyVideo="onlyVideo" :isCustomization="isCustomization"
        :updateVehicleTime="updateVehicleTime"
        checkOnNewGroup
        @node-click="onVehicleClick"
        @node-check="onVehicleCheck"
        @check-all="onCheckAll"
        @search-select="onSelectGroup"
        @updateVehicles="$emit('updateVehicles',$event)">
        <template v-if="$scopedSlots.vehicleAction"
          #action="{data}">
          <slot name="vehicleAction"
            :data="data"></slot>
        </template>
      </VehicleList>
    </template>
  </SideLayout>
</template>

<script>

import SideLayout from './SideLayout.vue';
import GroupTree from '@/components/GroupTree';
import VehicleList from '@/components/VehicleList';

export default {
  name: 'RealTimeSide',
  components: {
    SideLayout,
    GroupTree,
    VehicleList,
  },
  emits: [
    // 车组复选框变化时触发
    'group-check',
    // 车辆点击事件
    'vehicle-click',
    // 车辆复选框变化事件
    'vehicle-check',
    // 车辆全选事件
    'vehicle-check-all',
  ],
  props: {
    // 只显示视频设备
    onlyVideo: {
      type: Boolean,
      default: false,
    },
    /**
  * 车辆更新时间
  * 0: 不更新
  */
    updateVehicleTime: {
      type: Number,
      default: 0,
      validator(val) {
        return val === 0 || val >= 15;
      }
    },
    // 定制选项 默认全选车组
    isCustomization: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 勾选的车组Id数组
      groupIds: [],
    };
  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {
    clearAllGroup() {
      const { treeRef, vehicleRef } = this.$refs;
      if (!treeRef) return;
      treeRef.cancelAll();
    },
    async onCheckGroup(data, checked, allCheckeds, node) {
      const { treeRef, vehicleRef } = this.$refs;
      if (!treeRef) return;

      if (checked) {

        // 勾选上级节点, 同时勾选所有下级节点
        treeRef
          .getSubNodes(node, true)
          .forEach(subNode => {
            treeRef.setChecked(subNode, true, true);
          });

        await this.$nextTick();
      }

      // 获取所有勾选的节点
      allCheckeds = treeRef.getCheckedNodes();

      this.groupIds = allCheckeds.map(group => group.groupId);

      this.$emit('group-check', allCheckeds);
    },

    /**
     * 搜索选择事件
     */
    async onSelectGroup(data) {
      this.$umaApi.umaVehicleSelection('搜索选择车辆', data);
      if (data.vehicleId) {
        // await this.onVehicleCheck(data, true, data, [data])
        await this.onVehicleClick(data, true, data, [data]);
        return false;
      }
      const { treeRef } = this.$refs;
      const { groupId } = data;
      // treeRef && treeRef.setCheckedKey(groupId, true);
      treeRef && treeRef.scrollIntoView(groupId);
      this.groupIds.includes(groupId) ? '' : this.groupIds.push(groupId);
    },
    onVehicleClick(data) {
      this.$umaApi.umaVehicleSelection('选择车辆', data);
      // console.log(111);
      this.$emit('vehicle-click', data);
    },
    onVehicleCheck(data, checked, node, allCheckeds) {
      // console.log(111);
      // this.$umaApi.umaVehicleSelection('单个勾选', data, checked, node, allCheckeds);
      this.$emit('vehicle-check', data, checked, node, allCheckeds);
    },
    onCheckAll(checked, allCheckeds) {
      // console.log(111);
      // this.$umaApi.umaVehicleSelection('批量候选', checked, allCheckeds);
      this.$emit('vehicle-check-all', checked, allCheckeds);
    },
  }
}
</script>

<style lang="scss" scoped>
.RealTimeSideWrapper {
}
.topHeader {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
</style>