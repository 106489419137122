import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';

const axios = createHttp(appConfig.base2GUrl);


const get = (action, options) => axios.get(`systemutil/${action}.do`, options);
const post = (action, data, options) => axios.post(`systemutil/${action}.do`, data, {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  ...options
});


/**
 * 搜索省市区
 * @param {*} param.code //省市区代码，不传表示返回所有省
 * @param {*} param.level //返回省市区级别, 0：省； 1:市; 2:区
 */
export const DistrictSearchOptions = (code = '', level = 0) =>
  get('DistrictSearchOptions', { params: { code, level } })
    .then(res => {
      if (!res.flag) return [];
      //去重
      const arr = res.obj.map(p => ({ adcode: p.code.trim(), name: p.name.trim() }))
        .map(p => [p.adcode + p.name, p]);
      return [...new Map(arr).values()];
    });




