import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';

const axios = createHttp(appConfig.base2GUrl);


const get = (action, options) => axios.get(`vehicle/${action}.do`, options);


/**
 * 更新设置回传信息
 * vehicleId
 * arr：配置信息对象集合，[{"checked":true,"acc":0,"value":30},{"checked":true,"acc":1,"value":180},{"checked":false,"acc":2,"value":3}]
 */
export const updateAccRound = (vehicleId, arr) => get('updateAccRound', { params: { vehicleId, json: JSON.stringify(arr) } });

/**
 * 获取设置回传信息
 * vehicleId
 */
export const getAccTime = vehicleId => {
  return get('getAccTime', { params: { vehicleId } })
    .then(res => {
      let response = { ...res };
      if (res.flag === 1 && res.obj) {
        Object.assign(response, { result: JSON.parse(res.obj) });
      }
      return response;
    });
};

/**
 * 获取胎压模板
 */
export const getTyrePlane = () => {
  return get('getTyrePlane', { params: { userId: '' } }).then(res => {
    if (res.flag === 1 && res.obj && res.obj.length) {
      res.obj = res.obj.map(p => {
        return {
          planeName: p.planeName,
          tyreConf: JSON.parse(p.tyreConf)
        };
      });
    }
    return res;
  });
};


/**
 * 设置胎压模板
 * name
 * obj：胎压模板对象，{"temperatureUnit":"C","paUnit":"K","temperatureThreshold":50,"paCoefficient":"2.750","data":[{"coaxialNumber":1,"coaxial":"G","LValue":180,"HValue":200}]}
 */
export const setTyrePlane = (name, obj) => get('setTyrePlane', { params: { planeName: name, tyreJson: JSON.stringify(obj) } });

/**
 * 删除胎压模板
 * name
 */
export const delTyrePlane = name => get('delTyrePlane', { params: { planeName: name } });

/**
 *给车辆绑定胎压参数，车胎配对
 * @param {*} vehicleId 
 * @param {*} tyreObj ，胎压对象，{"temperatureUnit":"C","paUnit":"K","temperatureThreshold":50,"paCoefficient":"2.750","data":[{"coaxialNumber":1,"coaxial":"G","LValue":180,"HValue":200}]}
 * @param {*} pairArr ，车胎配对集合，[{"tireNumber":1,"tireDirection":"L","tireId":10000000}]
 */
export const doTyreBindVehicle = (groupId, vehicleId, tyreObj, pairArr) => get('doTyreBindVehicle', { params: { groupId, vehicleId, tyreJson: JSON.stringify(tyreObj), tyrePair: JSON.stringify(pairArr) } });

/**
 * 获取车胎信息
 * @param {*} vehicleId 
 */
export const getTyreBindInfo = vehicleId => {
  return get('getTyreBindInfo', { params: { vehicleId } })
    .then(res => {
      if (res.flag === 1 && res.obj) {
        res.obj.tyreConf = res.obj.tyreConf.trimsWith('[').trimsWith(']');
        res.obj.tyreConf = res.obj.tyreConf ? JSON.parse(res.obj.tyreConf) : null;
        res.obj.tyrePair = res.obj.tyrePair ? JSON.parse(res.obj.tyrePair) : [];
      }
      return res;
    });
};

/**
 * 搜索信息
 * @param {*} plate
 * @param {*} serchType
 */
export const searchVehicle = (plate, serchType = 0) => {
  return get('searchVehicle', { params: { plate, serchType } })
    .then(({ flag, obj }) => {
      if (flag && obj) {
        let types = [1, 3, 2];
        obj = obj.filter(p => p.type && (p.type == 1 || p.type == 2 || p.type == 3))
          .sort((a, b) => types.indexOf(a.type) - types.indexOf(b.type));
      }
      return { flag, obj };
    });
};

/**
 * 根据车辆ID获取车辆信息
 * @param {*} vehicleId 车辆ID
 */
export const getVehicleInfo = vehicleId => get('getVehicleInfo', { params: { vehicleId } });

export const directAddVehicle = (vehicle, extraData, VehicleAuthor, autoauth) => get('directAddVehicle', { params: { vehicle, extraData: JSON.stringify(extraData), VehicleAuthor, autoauth } })

export const updateVehicle = (vehicle, extraData, VehicleAuthor, autoauth) => get('updateVehicle', { params: { vehicle, extraData: JSON.stringify(extraData), VehicleAuthor, autoauth } })

/**
 * GPS设备安装台账报表查询
 * @param {*} params.groupId 车组Id
 * @param {*} params.vehicleId 车辆Id
 * @param {*} params.startTime 开始时间
 * @param {*} params.endTime 结束时间
 * @param {*} params.pageNumber
 * @param {*} params.pageSize
 */
export const PageInstallAccount = params => get('PageInstallAccount', { params: { ...params, flag: ~~!!params.vehicleId } });