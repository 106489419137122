<template>
  <el-dialog
    v-if="modal"
    :visible="modal"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    append-to-body
    @close="onModalChange(false)"
    width="1100px"
    title="编辑车辆资料"
  >
    <div slot="title" class="header-title">
      <span>车辆资料修改</span>
    </div>
    <div class="vehicle-info-content">
      <div class="content">
        <div class="pane">
          <span class="title">基本信息</span>
          <div class="pane-content">
            <div class="item item-cell3">
              <span class="first-item required">设备名称</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.plate"
                  :disabled="handleDisabled()"
                  :maxlength="21"
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item required">终端号</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.terminalNo"
                  :disabled="handleDisabled()"
                  :maxlength="20"
                  @keyup.native="terminalNoSet()"
                />
                <!-- isTmpOrMonitorUser || !isEdit -->
              </div>
              <el-tooltip
                class="question-tip"
                trigger="hover"
                style="display:none"
                placement="bottom-end"
              >
                <div class="el-tooltip-content" slot="content"></div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
            <div class="item item-cell3">
              <span class="first-item required">终端类型</span>
              <div class="last-item terminaltype-select">
                <el-select
                  v-model="terminalType"
                  :disabled="handleDisabled()"
                  @change="onChangeTerminalType"
                >
                  <el-option
                    v-for="terminal in terminalTypes"
                    :key="terminal.terminalType"
                    :value="terminal.terminalType"
                    :label="terminal.terminalType"
                  >
                    <div class="terminalTypeOption">
                      <span>{{ terminal.terminalType }}</span>
                      <span>
                        [{{
                        ["部标类", "视频类"][terminal.productType]
                        }}]
                      </span>
                    </div>
                  </el-option>
                </el-select>
                <el-tooltip
                  class="question-tip"
                  trigger="hover"
                  placement="bottom-end"
                >
                  <div class="el-tooltip-content" slot="content">
                    <ul>
                      <li>1、视频类转部标类：成功后剩余到期时间将无法退币</li>
                      <li>2、部标类转视频类：成功后将扣除您对应的授权币数，后续续费将按视频类设备收费</li>
                      <li>3、部标类转视频类：如果当前账号余额不足，无法转换为视频类设备</li>
                    </ul>
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </div>

            <div
              class="item item-cell3"
              v-if="['GPRS-部标'].includes(this.terminalType)"
            >
              <span class="first-item required">设备类型</span>
              <div class="last-item">
                <el-select
                  class="row-selectAddress"
                  v-model="vehicleInfo.gpsType"
                  filterable
                  placeholder="设备类型"
                >
                  <el-option label="有线设备无续航" :value="1"></el-option>
                  <el-option label="有线设备可续航" :value="2"></el-option>
                  <el-option label="无线设备" :value="3"></el-option>
                </el-select>
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item required">摄像头</span>
              <div class="last-item">
                <el-select
                  v-model.trim="cameras"
                  :disabled="handleDisabled()"
                  multiple
                  class="camera-select"
                >
                  <el-option
                    v-for="camera in 12"
                    :key="camera"
                    :value="camera"
                    :label="camera"
                  >摄像头{{ camera }}</el-option>
                </el-select>
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item required">所属车组</span>
              <div class="last-item car-group-dropdown">
                <el-input v-model="groupStr">
                  <SelectGroupPopover @nodeClick="onConfirmClick" slot="append"></SelectGroupPopover>
                </el-input>
                <!-- <input-query
                        v-model="groupStr"
                        dropdown
                        :disabled="handleDisabled()"
                        :searchProps="{ novehicle: true }"
                        :menuProps="{ className: 'dropmenu', novehicle: true }"
                        @select="onSelectQuery"
                        @on-confirm="onConfirmClick"
                ></input-query>-->
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item required">SIM卡号</span>
              <div class="last-item">
                <el-input
                  v-model="vehicleInfo.sim"
                  :disabled="handleDisabled()"
                  :maxlength="13"
                  @keyup.native="vehicleInfo.sim = vehicleInfo.sim.replace(/[^0-9]/g,'')"
                  placeholder="SIM卡号"
                  style="width:calc(100% - 81px);"
                />
                <el-input
                  v-model.trim="vehicleInfo.datausage"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : isTmpOrMonitorUser
                        "
                  :maxlength="10"
                  placeholder="套餐"
                  style="width:61px;margin-left:5px;"
                />
                <div style="width:40px;">(MB)</div>
              </div>
            </div>

            <!-- <el-cascader
                    :props="props"
                    clearable
                    :options="machineryProductTypeList"
            ></el-cascader>-->

            <!-- 产品线种类 -->
            <div class="item item-cell3">
              <span class="first-item required">产品线种类</span>
              <div class="last-item">
                <el-select
                  class="row-selectAddress"
                  v-model="vehicleInfo.machineryProductType"
                  filterable
                  placeholder="产品线种类"
                  clearable
                  @change="selectMachineryProductType"
                >
                  <el-option
                    v-for="item in machineryProductTypeList"
                    :key="item.dictType"
                    :label="item.dictName"
                    :value="item.dictType"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <!-- 产品类型 -->
            <div class="item item-cell3">
              <span class="first-item required">产品类型</span>
              <div class="last-item">
                <el-select
                  class="row-selectAddress"
                  v-model="vehicleInfo.machineryEquipmentType"
                  filterable
                  placeholder="产品类型"
                  clearable
                  @change="selectMachineryEquipmentType"
                >
                  <el-option
                    v-for="item in machineryEquipmentTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <!-- 产品版本-临时使用 -->
            <div class="item item-cell3">
              <span class="first-item required">产品版本</span>
              <div class="last-item">
                <el-select
                  class="row-selectAddress"
                  v-model="vehicleInfo.equipmentType"
                  filterable
                  placeholder="产品版本"
                  clearable
                >
                  <el-option
                    v-for="item in equipmentTypeList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item required">品牌</span>
              <div class="last-item">
                <el-select v-model.trim="vehicleInfo.vehicleBrand" clearable>
                  <el-option
                    :value="item.dictValue"
                    :label="item.dictLabel"
                    v-for="(item,index) in brandList"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">运营状态</span>
              <div class="last-item">
                <!-- <el-input v-model.trim="vehicleInfo.vehiclestate"
                :disabled="!isEdit" />-->
                <el-select
                  v-model.trim="vehicleInfo.vehiclestate"
                  clearable
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                >
                  <el-option value="运营">运营</el-option>
                  <el-option value="停运">停运</el-option>
                </el-select>
              </div>
            </div>

            <div class="item item-cell3">
              <span
                :class="
                        vehicleInfo.terminalNo.length > 11
                          ? 'first-item required'
                          : 'first-item'
                      "
              >终端IMEI</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.terminalIMEI"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span
                :class="
                        vehicleInfo.terminalNo.length > 11
                          ? 'first-item required'
                          : 'first-item'
                      "
              >终端ID</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.terminalId"
                  :maxlength="30"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span
                :class="
                        vehicleInfo.terminalNo.length > 11
                          ? 'first-item required'
                          : 'first-item'
                      "
              >制造商ID</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.manufacturerId"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  placeholder
                />
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">ICCID</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.iccid"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  :maxlength="20"
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">激活状态</span>
              <div class="last-item service-expires">
                <el-switch
                  v-model="vehicleInfo.activeType"
                  active-text="激活"
                  inactive-text="未激活"
                  :active-value="vehicleInfo.activeType === 3 ? 3 : 1"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : vehicleInfo.activeType * 1 === 3
                        "
                  inactive-value="0"
                ></el-switch>
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">终端服务费到期</span>
              <div class="last-item service-expires">
                <el-date-picker
                  style="width:190px"
                  v-model="vehicleInfo.renewalExpireDateStr"
                  type="datetime"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
                <el-tooltip
                  class="question-tip"
                  trigger="hover"
                  placement="bottom-end"
                >
                  <div
                    class="el-tooltip-content"
                    slot="content"
                  >终端服务费到期，非平台服务到期时间</div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">服务密码</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.serviceCode"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  :maxlength="8"
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">运营商</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.operator"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">运营类型</span>
              <div class="last-item">
                <el-select
                  v-model.trim="vehicleInfo.operatingTypes"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                >
                  <el-option
                    v-for="transformType in transformTypes"
                    :key="transformType"
                    :value="transformType"
                  >{{ transformType }}</el-option>
                </el-select>
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">行车记录仪版本</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.tripREC"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  placeholder="2012协议"
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">道路运输证号</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.roadPermit"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  :maxlength="20"
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">行车证号码</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.vehicleLicense"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  :maxlength="20"
                />
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">终端厂家型号</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.factoryNo"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  placeholder="GF03"
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">服务供应商</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.serviceProvider"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  placeholder
                />
              </div>
            </div>
            <!-- <div class="item item-cell3">
                    <span class="first-item">终端厂家代码</span>
                    <div class="last-item">
                      <el-input
                        v-model.trim="vehicleInfo.producerID"
                        placeholder
                        :maxlength="11"
                      />
                    </div>
            </div>-->
            <div v-if="is1078" class="item item-cell3">
              <span class="first-item">主动安全协议</span>
              <div class="last-item">
                <el-select
                  v-model.trim="vehicleInfo.percentageOfFlow"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                >
                  <el-option
                    v-for="protl in adasProtocolOptions"
                    :key="protl.value"
                    :value="protl.value"
                    :label="protl.label"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">自定义编号</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.customNo"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  placeholder
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">货物</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.goods"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  placeholder
                />
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">终端厂家代码</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.producerID"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  placeholder
                />
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">籍贯</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.area"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                  placeholder
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">所属行业</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.industry"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                />
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">业户名称</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.ownerproperty"
                  :disabled="
                          isEditVehicleInfo && !isWrite
                            ? handleDisabled()
                            : !isEdit
                        "
                />
              </div>
            </div>

            <!-- <div class="item item-cell3" v-if="IS_zhatuche_TYPE">
                    <span class="first-item required">车辆颜色</span>
                    <div class="last-item">
                      <el-select
                        clearable
                        v-model.trim="vehicleInfo.vehicleColor"
                        placeholder="颜色"
                        :disabled="isEditVehicleInfo?handleDisabled():false"
                      >
                        <el-option
                          v-for="color in vehicleColors"
                          :key="color"
                          :value="color"
                        >{{ color }}</el-option>
                      </el-select>
                    </div>
            </div>-->
            <div class="item item-cell3" v-if="IS_zhatuche_TYPE">
              <span class="first-item">车辆星标</span>
              <div class="last-item">
                <el-select
                  v-model.trim="vehicleInfo.carstar"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                  clearable
                >
                  <el-option
                    v-for="star in carstarOptions"
                    :key="star"
                    :value="star"
                  >{{ star }}</el-option>
                </el-select>
              </div>
            </div>

            <div class="item item-cell3" v-if="IS_zhatuche_TYPE">
              <span class="first-item">车辆新旧</span>
              <div class="last-item">
                <el-select
                  v-model.trim="vehicleInfo.catage"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                  clearable
                >
                  <el-option value="新车">新车</el-option>
                  <el-option value="旧车">旧车</el-option>
                </el-select>
              </div>
            </div>
            <div class="item item-cell3" v-if="IS_zhatuche_TYPE">
              <span class="first-item">有效期</span>
              <div class="last-item datetime-input-item">
                <el-date-picker
                  v-model="vehicleInfo.validitytime"
                  type="datetime"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </div>
            </div>

            <div class="item item-cell2" v-if="IS_zhatuche_TYPE">
              <span class="first-item required">货箱体积</span>
              <div class="last-item">
                <el-select
                  class="volume-item"
                  v-model.trim="Volume_Static"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                  clearable
                >
                  <el-option :value="0">体积</el-option>
                  <el-option :value="1">长宽高</el-option>
                </el-select>
                <div class="container-item" v-if="Volume_Static">
                  <el-input
                    v-for="(item, index) in containerOptions"
                    :placeholder="item.placeholder"
                    v-model="item.len"
                    style="width:50px;"
                    :key="index"
                    :disabled="
                            isEditVehicleInfo ? handleDisabled() : !isEdit
                          "
                  />
                  <span style="display:inline-block;margin-left:20px;">m³</span>
                </div>
                <div class="containervolume-item" v-else>
                  <el-input
                    v-model.trim="vehicleInfo.containervolume"
                    placeholder="请输入货箱体积"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="pane" v-if="hasPerms('M07SM06')">
          <span class="title">自动授权</span>
          <div class="pane-content ed-content">
            <div class="last-item">
              <div style="width: 100%;margin: 0 0 20px 0;">
                <el-checkbox
                  v-model="renewChecked"
                  :disabled="isEditVehicleInfo ? handleDisabled() : !isEdit"
                  @change="renewChange"
                >自动授权</el-checkbox>
              </div>
              <div v-if="renewChecked">
                <p>
                  续费时长：
                  <span>
                    <el-radio-group
                      v-model="VehicleAuthor.authortmonth"
                      size="mini"
                      @change="changeCoin"
                      :disabled="isEditVehicleInfo ? handleDisabled() : false"
                    >
                      <el-radio-button label="3">3月</el-radio-button>
                      <el-radio-button label="6">6月</el-radio-button>
                      <el-radio-button label="12">1年</el-radio-button>
                      <el-radio-button label="36">3年</el-radio-button>
                      <el-radio-button label="60">5年</el-radio-button>
                    </el-radio-group>
                  </span>
                </p>
                <p>
                  消耗
                  <strong
                    style="font-size:24px;color:#dd2421;margin:0 5px;"
                  >{{ coin }}</strong>个授权币
                </p>
              </div>
            </div>

            <span>注：请勾选车辆对接的相关外设传感器信息，否则将会影响该车辆的传感器报表数据查询</span>
          </div>
        </div>-->
        <div class="pane">
          <span class="title">安装信息</span>
          <div class="pane-content">
            <div class="item item-cell3">
              <span class="first-item">业务员</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.salesman"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">录入员</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.recordPerson"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                  :maxlength="20"
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">公司名称</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.company"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                  :maxlength="20"
                />
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">安装时间</span>
              <div class="last-item datetime-input-item">
                <el-date-picker
                  v-model="vehicleInfo.installDate"
                  type="datetime"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">安装人员</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.installPerson"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">工单编号</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.orderNumber"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">安装地点</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.installPlace"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span
                :class="
                        vehicleInfo.terminalNo.length > 11
                          ? 'first-item required'
                          : 'first-item'
                      "
              >车籍地</span>
              <div class="last-item">
                <el-select
                  class="vehicle-place-input"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                  v-model.trim="vehiclePlace.province"
                  :title="vehiclePlace.province"
                  placeholder="省"
                  @change="onChangeProvinceVehiclePlace"
                >
                  <el-option
                    v-for="province in provinces"
                    :key="province.adcode"
                    :value="province.name"
                  >{{ province.name }}</el-option>
                </el-select>
                <el-select
                  class="vehicle-place-input"
                  v-model.trim="vehiclePlace.city"
                  :title="vehiclePlace.city"
                  placeholder="市"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                  @change="onChangeCityVehiclePlace"
                >
                  <el-option
                    v-for="city in vehiclePlace.cities"
                    :key="city.adcode"
                    :value="city.name"
                  >{{ city.name }}</el-option>
                </el-select>
                <el-select
                  class="vehicle-place-input"
                  v-model.trim="vehiclePlace.district"
                  :title="vehiclePlace.district"
                  placeholder="区"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                >
                  <el-option
                    v-for="district in vehiclePlace.districts"
                    :key="district.adcode"
                    :value="district.name"
                  >{{ district.name }}</el-option>
                </el-select>
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">座位/吨位</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.seat"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">年审日期</span>
              <div class="last-item datetime-input-item">
                <el-date-picker
                  v-model="vehicleInfo.nextReturnDueStr"
                  type="datetime"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">车架号</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.frameNo"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                  :maxlength="20"
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">车辆品牌</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.brand"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                />
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">车牌颜色</span>
              <div class="last-item">
                <el-select
                  v-model.trim="vehicleInfo.plateColor"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                  placeholder="颜色"
                >
                  <el-option
                    v-for="color in plateColors"
                    :key="color"
                    :value="color"
                  >{{ color }}</el-option>
                </el-select>
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">车辆颜色</span>
              <div class="last-item">
                <el-select
                  clearable
                  v-model.trim="vehicleInfo.vehicleColor"
                  placeholder="颜色"
                  :disabled="isEditVehicleInfo ? handleDisabled() : false"
                >
                  <el-option
                    v-for="color in vehicleColors"
                    :key="color"
                    :value="color"
                  >{{ color }}</el-option>
                </el-select>
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">发动机编号</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.engineNo"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                />
              </div>
            </div>

            <div class="item item-cell3">
              <span class="first-item">油卡号</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.gasCard"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                  placeholder="多个油卡号请用“ , ”号分隔"
                />
              </div>
            </div>
            <div class="item item-cell3">
              <span class="first-item">加油型号</span>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.gasType"
                  :disabled="
                          isEditVehicleInfo ? handleDisabled() : !isEdit
                        "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="pane">
          <span class="title">对接外设</span>
          <div class="pane-content ed-content">
            <div>
              <el-checkbox
                class="ed-checkbox"
                :disabled="isEditVehicleInfo ? handleDisabled() : !isEdit"
                v-for="device in peripherals"
                v-model="device.checked"
                :key="device.label"
              >
                <div class="ed-label">
                  {{ device.label }}
                  <div v-if="device.isHot" class="hot-icon"></div>
                </div>
              </el-checkbox>
            </div>
            <span>注：请勾选车辆对接的相关外设传感器信息，否则将会影响该车辆的传感器报表数据查询</span>
          </div>
        </div>
        <div class="pane">
          <span class="title">车主信息</span>
          <div class="pane-content">
            <ul>
              <li>
                <div class="item item-cell3">
                  <span class="first-item">车主名称</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim="vehicleInfo.owner"
                      :disabled="!isEdit"
                      :maxlength="20"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">
                    {{
                    IS_CL211 ? "紧急联系人" : "行驶证号"
                    }}
                  </span>
                  <div class="last-item">
                    <el-input
                      v-model.trim="vehicleInfo.license"
                      :disabled="!isEdit"
                      :maxlength="20"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">联系电话</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim="vehicleInfo.phone"
                      :disabled="!isEdit"
                      :maxlength="20"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="pane">
          <span class="title">单位信息</span>
          <div class="pane-content">
            <ul>
              <li>
                <div class="item item-cell3">
                  <span class="first-item">业户名称</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim="extraData.ownerName"
                      :disabled="!isEdit"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">业户电话</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim.number="extraData.ownerPhone"
                      :disabled="!isEdit"
                      :maxlength="13"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">所属区域</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim="extraData.area"
                      :disabled="!isEdit"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="item item-cell3">
                  <span class="first-item">单位地址</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim="extraData.unitAddress"
                      :disabled="!isEdit"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">负责人手机</span>
                  <div class="last-item owner-item">
                    <el-input
                      v-model.trim.number="unitInfo.cellNo1"
                      :maxlength="13"
                      :disabled="!isEdit"
                      placeholder="手机号码1"
                    />
                    <el-input
                      v-model.trim.number="unitInfo.cellNo2"
                      :maxlength="13"
                      :disabled="!unitInfo.cellNo1 || !isEdit"
                      placeholder="手机号码2"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">负责人座机</span>
                  <div class="last-item owner-item">
                    <el-input
                      v-model.trim="unitInfo.telNo1"
                      :maxlength="13"
                      :disabled="!isEdit"
                      placeholder="座机1"
                    />
                    <el-input
                      v-model.trim="unitInfo.telNo2"
                      :maxlength="13"
                      :disabled="!unitInfo.telNo1"
                      placeholder="座机2"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="pane">
          <span class="title">营运线路</span>
          <div class="pane-content">
            <div class="item item-cell1">
              <div class="first-item"></div>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.staypoint"
                  type="textarea"
                  :rows="5"
                  :maxlength="100"
                  :disabled="!isEdit"
                  placeholder="请输入内容"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="pane">
          <span class="title">自定义信息展示栏</span>
          <div class="pane-content">
            <div class="item item-cell1">
              <div class="first-item"></div>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.customMessage"
                  type="textarea"
                  :rows="5"
                  :disabled="!isEdit"
                  :maxlength="100"
                  placeholder="请输入内容"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="pane">
          <span class="title">保险信息</span>
          <div class="pane-content">
            <ul>
              <li>
                <div class="item item-cell3">
                  <span class="first-item">交强险金额</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim.number="extraData.sali"
                      :maxlength="8"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">车损险金额</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim.number="extraData.dlw"
                      :maxlength="8"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">车船使用税</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim.number="extraData.ix"
                      :maxlength="8"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="item item-cell3">
                  <span class="first-item">盗抢险金额</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim.number="extraData.robStolenRisk"
                      :maxlength="8"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">第三者责任险</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim.number="extraData.thirdPartyLiability"
                      :maxlength="8"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">车上人员责任险</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim.number="extraData.liabilityInsurance"
                      :maxlength="8"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="item item-cell3">
                  <span class="first-item">自燃险金额</span>
                  <div class="last-item">
                    <el-input
                      v-model.trim.number="extraData.naturalRisk"
                      :maxlength="8"
                    />
                  </div>
                </div>
                <div class="item item-cell3">
                  <span class="first-item">保险到期时间</span>
                  <div class="last-item datetime-input-item">
                    <el-date-picker
                      v-model="extraData.insuranceDueDateStr"
                      type="datetime"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                  </div>
                </div>
                <!-- <div class="item item-cell3"></div> -->
              </li>
            </ul>
          </div>
        </div>
        <div class="pane">
          <span class="title">备注信息</span>
          <div class="pane-content">
            <div class="item item-cell1">
              <div class="first-item"></div>
              <div class="last-item">
                <el-input
                  v-model.trim="vehicleInfo.remark"
                  type="textarea"
                  :rows="8"
                  :disabled="!isEdit"
                  :maxlength="500"
                  placeholder="请输入备注"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <!--
            配置是否有修改基础信息权限 isEditVehicleInfo
            无权限账号   isWrite：0    isTmpOrMonitorUser：false
            有权限       isWrite:true  isTmpOrMonitorUser:false
            system账号   isWrite:true  isTmpOrMonitorUser:false
            :disabled="isTmpOrMonitorUser || !isWrite"
        -->
        <el-button
          type="primary"
          size="small"
          :disabled="
              isEditVehicleInfo
                ? !isEditVehicleInfo
                : isTmpOrMonitorUser || !isWrite
            "
          @click="onSaveData"
        >保存</el-button>
        <el-button size="default" @click="onModalChange(false)">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// import "@/js/directives.js"; //拖拽功能JS
import { mapState } from "vuex";
import { DistrictSearchOptions as getDistrict } from "@/api/util-api";
import moment from 'dayjs';
import InputQuery from "@/components/SearchVehicleGroup";
import SelectGroupPopover from "@/components/SelectGroupPopover";//1
import { phoneFun } from "@/utils/index";
import {
  getVehicleInfo,
  directAddVehicle,
  updateVehicle
} from "@/api/live-vehicle-api";
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";

import { queryMyState } from "@/api/getManagementRenew";
import { hasPerms } from "@/utils/auth";


import { getProductTypeIconName } from '@/utils/getProductTypeIconName';
import { getBrandList } from "@/api/getData";


export default {
  name: "ModalVehicleInfo",
  components: {
    InputQuery,
    SelectGroupPopover
  },
  model: {
    prop: "modal",
    event: "change"
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    vehicleId: {
      type: Number,
      default: 0
    },
    groupId: {
      type: Number,
      default: -1,
      required: false
    },
    groupName: {
      type: String,
      default: "",
      required: false
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    let id = 0;
    return {

      machineryProductTypeList: [],
      machineryEquipmentTypeList: [],
      equipmentTypeList: [{
        name: '智慧版',
      }, {
        name: '智慧版Plus',
      }, {
        name: '智慧版mini',
      }, {
        name: '效能版',
      }, {
        name: '鹰眼版',
      }],
      groupStr: "",
      cameras: [1, 2, 3, 4],
      provinces: [],
      activeNames: [" "],
      vehiclePhotos: [],
      IS_zhatuche_TYPE: false,
      vehiclePlace: {
        cities: [],
        districts: [],
        province: "",
        city: "",
        district: ""
      },
      terminalType: "GB-1078",
      vehicleInfo: this.initVehicleInfo(),
      unitInfo: this.initUnitInfo(),
      extraData: this.initExtraData(),
      vehicleAuthorVO: this.initVehicleAuthor(),
      terminalTypes: [
        { terminalType: "GPRS-部标", productType: 0, coinPer: 10 },
        // { terminalType: "GF-1078", productType: 1, coinPer: 25 },
        { terminalType: "GB-1078", productType: 1, coinPer: 25 },
        // { terminalType: "GA系列", productType: 1, coinPer: 25 }
      ],
      plateColors: [
        //车牌颜色
        "蓝色",
        "黄色",
        "绿色",
        "农黄色",
        "农绿色",
        "黄绿色",
        "渐变绿",
        "其他"
      ],
      vehicleColors: [
        //车辆颜色
        "红色",
        "蓝色",
        "黑色",
        "白色",
        "黄色",
        "绿色"
      ],
      carstarOptions: [
        // 车辆星标
        "普通车",
        "星标车辆",
        "重点星标"
      ],
      transformTypes: [
        "道路旅客运输",
        "班线客运",
        "道路货物运输",
        "道路危险货物运输",
        "机动车维修",
        "机动车驾驶员培训",
        "站场服务",
        "国际道路运输",
        "公交运输",
        "出租运输",
        "汽车租赁",
        "包车客运",
        "定线旅游",
        "班车客运",
        "其他"
      ],
      containerOptions: [
        {
          len: "",
          placeholder: "长/m"
        },
        {
          len: "",
          placeholder: "宽/m"
        },
        {
          len: "",
          placeholder: "高/m"
        }
      ], //长宽高
      peripherals: this.initPeripherals(),
      adasProtocolOptions: [
        { value: 0, label: "苏标" },
        { value: 4, label: "沪标" },
        { value: 1, label: "佛山标准" },
        { value: 2, label: "河北标准" },
        { value: 3, label: "吉林标准" },
        { value: 5, label: "黑龙江标准" },
        { value: 6, label: "粤标" },
        { value: 7, label: "陕西标准" },
        { value: 8, label: "川标" }
      ],

      //部标
      GPRSLength: [
        {
          month: 3,
          coin: 3
        },
        {
          month: 6,
          coin: 6
        },
        {
          month: 12,
          coin: 10
        },
        {
          month: 36,
          coin: 30
        },
        {
          month: 60,
          coin: 50
        }
      ],
      //视频
      GFLength: [
        {
          month: 3,
          coin: 10
        },
        {
          month: 6,
          coin: 20
        },
        {
          month: 12,
          coin: 25
        },
        {
          month: 36,
          coin: 75
        },
        {
          month: 60,
          coin: 125
        }
      ],
      coin: 0,
      autoauth: "", //是否自动授权
      VehicleAuthor: {}, // 续费时长
      renewChecked: "",
      coniForm: [],
      Volume_Static: 0,
      // 品牌列表
      brandList: []
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    //修改资料权限
    isEditVehicleInfo() {
      return true;
      // return bsjconfig.isEditVehicleInfo;
    },
    isTmpOrMonitorUser() {
      return this.isMonitorUser;
    },
    isWrite() {
      return true;
      return (
        (!this.vehicleId && this.hasPerms("M07SM02F04")) ||
        (this.vehicleId && this.hasPerms("M07SM02F05"))
      );
    },
    is1078() {
      return ["GF-1078", "GB-1078"].includes(this.terminalType);
    },
    IS_CL211() {
      return true;
      return (
        bsjconfig.customerCodes && bsjconfig.customerCodes.includes("CL211")
      );
    }
  },
  watch: {
    modal: {
      immediate: true,
      handler: function (val) {
        this.initData();
        if (!val) return;

        // this.renewChecked = false;
        this.vehicleInfo.groupId = this.groupId;
        this.vehicleInfo.groupName = this.groupName;
        this.groupStr = this.groupName;
        if (!this.vehicleId) return;

        this.getVehicleInfoByid(this.vehicleId);

      }
    },
    "vehicleInfo.vehicleShape": {
      handler: function (val) {
        if (val === "渣土车") {
          this.IS_zhatuche_TYPE = true;
          this.vehicleInfo.flag = "1";
        } else {
          this.IS_zhatuche_TYPE = false;
          this.vehicleInfo.flag = "";
        }
      }
    },
    Volume_Static: {
      handler: function (val) {
        if (val === 0) {
          for (let i = 0; i < this.containerOptions.length; i++) {
            this.containerOptions[i].len = "";
          }
          this.vehicleInfo.container = "";
        } else {
          this.vehicleInfo.containervolume = "";
        }
      }
    }
  },
  mounted() {
    this.getMachineryProductTypeList();
    this.getBrandList();

    if (this.vehicleId * 1 === 0) {
      this.VehicleAuthor.authoruser = this.userInfo.userId;
    }
  },
  beforeMount() {
    getDistrict().then(res => {
      this.provinces = res;
    });
  },
  methods: {
    getProductTypeIconName,
    hasPerms,
    // 获取产品类型列表
    getMachineryProductTypeList() {
      getDictList({ type: '1' }).then(res => {
        if (res.flag === 1) {
          this.machineryProductTypeList = res.rows;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    getBrandList() {
      getBrandList({
        dictType: 'vehicle_brand',
        platform: 'PC'
      }).then(res => {
        if (res.flag === 1) {
          this.brandList = res.obj;
        }
      });
    },


    // 根据车辆id获取车辆信息
    async getVehicleInfoByid(vehicleId) {


      try {
        const result = await getVehicleInfo(vehicleId);

        let { dvehicleInfo, extraData, vehicleAuthorVO } = result.obj;

        Object.keys(dvehicleInfo).forEach(key => {
          if (key === "containervolume" && dvehicleInfo[key] === "") {
            this.Volume_Static = 1;
            if (dvehicleInfo.container) {
              dvehicleInfo.container.split(",").map((item, index) => {
                this.containerOptions[index].len = item;
              });
            }
          }
          this.vehicleInfo.hasOwnProperty(key) ||
            key === "appendixnum" ||
            key === "appendixid" ||
            delete dvehicleInfo[key];
        });

        if (dvehicleInfo.cameraLine.trim())
          this.cameras = JSON.parse(dvehicleInfo.cameraLine.trim());

        let [province, city, district] = (
          dvehicleInfo.carhrough || ""
        ).split(",");
        let [cellNo1, cellNo2] = (extraData.principalPhone || "").split(
          ","
        );
        let [telNo1, telNo2] = (extraData.principalPlane || "").split(",");

        if (dvehicleInfo.peripheral) {
          this.peripherals.forEach(
            p => (p.checked = !!(Number(dvehicleInfo.peripheral) & p.value))
          );
        } else {
          this.peripherals.forEach(p => (p.checked = false));
        }

        this.unitInfo.cellNo1 = cellNo1 || "";
        this.unitInfo.cellNo2 = cellNo2 || "";
        this.unitInfo.telNo1 = telNo1 || "";
        this.unitInfo.telNo2 = telNo2 || "";
        this.vehiclePlace.province = province || "";
        this.vehiclePlace.city = city || "";
        this.vehiclePlace.district = district || "";
        this.terminalType = dvehicleInfo.terminalType;

        Object.assign(this.vehicleInfo, dvehicleInfo);
        Object.assign(this.extraData, extraData);
        if (vehicleAuthorVO) {
          Object.assign(this.vehicleAuthorVO, vehicleAuthorVO);
        }

        if (this.vehiclePlace.province)
          this.onChangeProvinceVehiclePlace(this.vehiclePlace.province);
        this.groupStr = this.vehicleInfo.groupName;


        // 查询设备类型
        if (dvehicleInfo.machineryProductType) this.getMachineryEquipmentTypeList();


        // if (result.obj.hasOwnProperty("vehicleAuthorVO") && vehicleAuthorVO) {
        //   if (vehicleAuthorVO.authortmonth !== "") {
        //     this.autoauth = 0;
        //     this.VehicleAuthor.authortmonth = vehicleAuthorVO.authortmonth;
        //     this.renewChecked = true;
        //   }
        //   if (dvehicleInfo.terminalType === "GPRS-部标") {
        //     this.coniForm = this.GPRSLength.filter(
        //       p => p.month * 1 === vehicleAuthorVO.authortmonth * 1
        //     );
        //     this.coin = this.coniForm[0].coin;
        //   } else {
        //     this.coniForm = this.GFLength.filter(
        //       p => p.month * 1 === vehicleAuthorVO.authortmonth * 1
        //     );
        //     this.coin = this.coniForm[0].coin;
        //   }
        // }

      } catch (error) {
        this.$message.warning('查询异常');
        console.log(error);
      }
    },

    // 获取设备类型列表
    getMachineryEquipmentTypeList() {

      getDictType({ dictType: this.vehicleInfo.machineryProductType }).then(res => {
        if (res.flag === 1) {
          this.machineryEquipmentTypeList = res.obj;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 选择产品类型
    selectMachineryProductType() {
      this.getMachineryEquipmentTypeList();
    },
    // 选择设备类型
    selectMachineryEquipmentType() {

    },
    //判断是否能编辑
    handleDisabled() {
      return this.isEditVehicleInfo && !this.isWrite
        ? this.isEditVehicleInfo
        : this.isTmpOrMonitorUser || !this.isEdit;
    },
    terminalNoSet() {
      this.vehicleInfo.terminalNo = this.vehicleInfo.terminalNo.replace(
        /[^0-9]/g,
        ""
      );
      if (
        this.vehicleInfo.terminalNo.length > 11 &&
        !this.vehicleInfo.manufacturerId.length
      ) {
        this.vehicleInfo.manufacturerId = "000070111";
      }
    },
    onModalChange(modal, eventName = "on-cancel", ...options) {
      this.groupStr = "";
      this.$emit("change", modal);
      this.$emit(eventName, ...options);
    },
    validateData() {
      let {
        plate,
        terminalNo,
        terminalIMEI,
        terminalId,
        sim,
        groupName,
        manufacturerId,
        groupId,
        vehicleColor,
        container,
        containervolume,
        machineryEquipmentType,
        machineryProductType,
        gpsType,
        vehicleBrand
      } = this.vehicleInfo;

      let singList = ["!", "@", "#", "￥", "%", "……", "&", "*"];
      if (singList.some(res => plate.includes(res))) {
        this.$message({
          type: 'warning',
          message: "设备名称不能带特殊符号"
        });
        return false;
      }

      if (['GPRS-部标'].includes(this.terminalType)) {
        if (!gpsType) {
          this.$message({
            type: 'warning',
            message: '请选择设备类型'
          });
          return false;
        }
      }
      if (!machineryProductType) {
        this.$message({
          type: 'warning',
          message: '请选择产品类型'
        });
        return false;
      }
      if (!machineryEquipmentType) {
        this.$message({
          type: 'warning',
          message: '请选择设备类型'
        });
        return false;
      }
      if (!vehicleBrand) {
        this.$message({
          type: 'warning',
          message: '请选择车辆品牌'
        });
        return false;
      }

      if (this.extraData.ownerPhone && !phoneFun(this.extraData.ownerPhone)) {
        this.$message({
          type: 'warning',
          message: '请输入正确的业户电话'
        });
        return false;
      }


      //判断是否为渣土车类型
      if (this.IS_zhatuche_TYPE) {
        if (
          !containervolume &&
          this.containerOptions.find(p => p.len === "") !== undefined
        ) {
          this.$message({
            type: 'warning',
            message: "货箱体积请填写完整"
          });
          return false;
        }

        if (!vehicleColor) {
          this.$message({
            type: 'warning',
            message: '车辆颜色不能为空'
          });
          return false;
        }
        if (containervolume === "") {
          //拼接长宽高
          this.vehicleInfo.container = "";
          this.containerOptions.map((item, index) => {
            if (index < this.containerOptions.length - 1) {
              this.vehicleInfo.container += item.len + ",";
            } else {
              this.vehicleInfo.container += item.len;
            }
          });
        }
      }
      if (plate.length < 3) {
        this.$message({
          type: 'warning',
          message: '设备名称最少3位'
        });
        return false;
      }

      if ((terminalNo + "").length !== 11 && (terminalNo + "").length !== 20) {
        this.$message({
          type: 'warning',
          message: '终端编号必须11位或20位纯数字'
        });
        return false;
      }
      if (terminalNo.toString().length === 20) {
        if (!this.vehiclePlace.province.length) {
          this.$message({
            type: 'warning',
            message: '安装信息-车籍地不能为空'
          });
          return false;
        }
        if (!terminalIMEI) {
          this.$message({
            type: 'warning',
            message: '终端IMEI号不能为空'
          });
          return false;
        }
        if (!terminalId) {
          this.$message({
            type: 'warning',
            message: '终端ID不能为空'
          });
          return false;
        }
        if (!manufacturerId) {
          this.$message({
            type: 'warning',
            message: '制造商ID不能为空'
          });
          return false;
        }
      }
      if (![11, 13].includes((sim + "").length)) {
        this.$message({
          type: 'warning',
          message: 'SIM卡号必须11位纯数字'
        });
        return false;
      }
      if (!(groupName.length && groupId > -1)) {
        this.$message({
          type: 'warning',
          message: '请选择有效车组'
        });
        return false;
      }
      if (!this.cameras.length) {
        this.$message.warning('摄像头不能为空');
        return false;
      }

      if (
        [
          (this.extraData.sali + "").length > 8,
          (this.extraData.dlw + "").length > 8,
          (this.extraData.ix + "").length > 8,
          (this.extraData.robStolenRisk + "").length > 8,
          (this.extraData.thirdPartyLiability + "").length > 8,
          (this.extraData.liabilityInsurance + "").length > 8,
          (this.extraData.naturalRisk + "").length > 8
        ].includes(true)
      ) {
        this.$message({
          type: 'warning',
          message: '保险金额必须小于8位纯数字'
        });
        return;
      }
      return true;
    },
    onSaveData() {
      if (!this.validateData()) return;
      if (!this.vehicleInfo.vehicleShape)
        this.vehicleInfo.vehicleShape = "其他";
      this.vehicleInfo.terminalType = this.terminalType;
      this.vehicleInfo.carhrough = `${ this.vehiclePlace.province },${ this.vehiclePlace.city },${ this.vehiclePlace.district }`;
      this.vehicleInfo.cameraLine = JSON.stringify(
        this.cameras.sort((a, b) => a - b)
      );
      this.vehicleInfo.cameraNum = this.cameras.length;
      this.vehicleInfo.peripheral = this.peripherals.reduce(
        (acc, p) => (p.checked ? (acc += p.value) : acc),
        0
      );
      // if (this.renewChecked) {
      //   if (this.VehicleAuthor.authortmonth === "") {
      //     this.$message({
      //       type: 'warning',
      //       message: '请选择续费时长'
      //     });
      //     return;
      //   }
      // }

      this.extraData.principalPhone = `${ this.unitInfo.cellNo1 },${ this.unitInfo.cellNo2 }`;
      this.extraData.principalPlane = `${ this.unitInfo.telNo1 },${ this.unitInfo.telNo2 }`;
      this.vehicleInfo.gasCard = this.vehicleInfo.gasCard ? this.vehicleInfo.gasCard.replace(/，/g, ",") : '';//油卡号中文逗号替换为英文逗号

      let action = [directAddVehicle, updateVehicle][
        ~~!!this.vehicleInfo.vehicleId
      ];
      let uuid = "";
      const addFiles = this.vehiclePhotos.filter(p => !p.uuid && p.file);
      const upFiles = this.vehiclePhotos.filter(p => {
        if (p.uuid) uuid = p.uuid;
        return p.uuid && p.file;
      });
      action(
        this.vehicleInfo,
        this.extraData,
        this.VehicleAuthor,
        this.autoauth
      ).then(res => {
        console.log('helpo');
        if (res.msg) this.$message({
          type: 'success',
          message: res.msg
        });
        if (res.flag) {
          const upParams = {
            uuid: upFiles.filter(p => p.file).map(p => p.uuid)[0],
            file: upFiles.map(p => p.file),
            sorts: upFiles.map(p => p.sort)
          };

          const addParams = {
            uuid,
            vehicleId:
              this.vehicleInfo.vehicleId === 0
                ? res.obj.vehicleId
                : this.vehicleInfo.vehicleId,
            file: addFiles.map(p => p.file),
            sorts: addFiles.map(p => p.sort)
          };
          if (upFiles.length) updateVehiclePhotos(upParams);
          if (addFiles.length) uploadVehiclePhotos(addParams);
          this.$store.getVehiclesAgain = true;
          this.onModalChange(false, "on-ok", {
            vehicle: this.vehicleInfo,
            extraData: this.extraData,
            VehicleAuthor: this.VehicleAuthor,
            autoauth: this.autoauth
          });

        }
      });
    },
    onChangeProvinceVehiclePlace(value) {
      const province = this.provinces.find(p => p.name === value);
      if (!province) return;
      getDistrict(province.adcode, 1).then(res => {
        this.vehiclePlace.cities = res;
        if (!res.some(p => p.name === this.vehiclePlace.city)) {
          this.vehiclePlace.city = res.length ? res[0].name : "";
          this.vehiclePlace.district = "";
        }
        this.onChangeCityVehiclePlace(this.vehiclePlace.city);
      });
    },
    onChangeCityVehiclePlace(value) {
      const city = this.vehiclePlace.cities.find(p => p.name === value);
      if (!city) return;
      getDistrict(city.adcode, 2).then(res => {
        this.vehiclePlace.districts = res;
        if (!res.some(p => p.name === this.vehiclePlace.district)) {
          this.vehiclePlace.district = res.length ? res[0].name : "";
        }
      });
    },
    onConfirmClick(group) {
      const { groupId, groupName, groupType } = group;
      this.vehicleInfo.groupId = ~~groupId;
      this.vehicleInfo.groupName = groupName;
      this.groupStr = groupName;
      if (!this.vehicleInfo.vehicleShape)
        this.vehicleInfo.vehicleShape = groupType || "其他";
    },
    onSelectQuery(item) {
      const { vehicleId, groupId, plate, groupName, type, groupType } = item;
      this.vehicleInfo.groupId = ~~groupId;
      this.vehicleInfo.groupName = groupName;
      if (!this.vehicleInfo.vehicleShape)
        this.vehicleInfo.vehicleShape = groupType || "其他";
    },
    onSelectPhoto(files, extra) {
      extra.file = files;
    },
    async onChangeTerminalType(value) {
      let { vehicleId, terminalType, expireDate } = this.vehicleInfo;
      let targetTerminalType = this.terminalTypes.find(
        p => p.terminalType === value
      );
      let sourceTerminalType = this.terminalTypes.find(
        p => p.terminalType === terminalType
      );

      //
      if (
        vehicleId &&
        expireDate &&
        sourceTerminalType.productType !== targetTerminalType.productType
      ) {
        let remainDay =
          (new Date(expireDate).getTime() - new Date().getTime()) /
          1000 /
          60 /
          60 /
          24 +
          1;
        remainDay = remainDay < 0 ? 0 : remainDay;
        if (!remainDay) return; //已过期
        let productTypes = ["部标类", "视频类"];
        let tips = {
          0: "视频类转部标类，成功后剩余到期时间将无法退币",
          1: "部标类转视频类，成功后将扣除您对应的授权币数，后续续费将按视频类设备收费",
          2: "部标类转视频类，当前账号余额不足，无法转换为视频类设备"
        };

        let result = await queryMyState();
        let {
          flag,
          obj: {
            money: { name, money }
          }
        } = result;
        if (result.flag) {
          let tip = tips[0];
          let deduct; // 应扣除授权币
          if (targetTerminalType.productType === 1) {
            tip = tips[1];
            deduct = Math.round((15 * remainDay) / 365);
            if (money < deduct) tip = tips[2];
          }
          const confirmRes = await this.$alert(
            `<div style="position: relative;color:#79808f;font-size:13px;">
                  <ul>
                    <li>用户名：${ name }</li>
                    <li>
                      授权币：<span style="color:#ff4444;">${ money }</span>
                    </li>
                    <li>
                      过期时间：
                      <span style="color:#ff4444;">
                        ${ moment(new Date(expireDate)).format("YYYY-MM-DD") }
                      </span>
                    </li>
                    <li>
                      剩余天数：
                      <span style="color:#ff4444;">
                        ${ Math.round(remainDay) }
                      </span>
                      &nbsp;天
                    </li>
                    <li>
                      操作说明：
                      <span style="color:#ff4444;">
                        ${ productTypes[sourceTerminalType.productType] }（
                        ${ sourceTerminalType.terminalType }）
                      </span>
                      &nbsp;&nbsp;转&nbsp;&nbsp;
                      <span style="color:#ff4444;">
                        ${ productTypes[targetTerminalType.productType] }（
                        ${ targetTerminalType.terminalType }）
                      </span>
                    </li>
                    <li style="display: ${ ["none", ""][targetTerminalType.productType]
            };">
                      应扣除授权币：<span style="color:#ff4444;">${ deduct }</span>
                    </li>
                    <li style="color:#2d8cf0">
                      温馨提示！
                      <ul>
                        <li>
                          1、<span>${ tip };</span>
                        </li>
                        <li>
                          2、<span>如有疑问请联系商务;</span>
                        </li>
                        <li style=${ { display: ["none", ""][0] } }>
                          3、
                          <span>
                            扣除授权币 = 15 * 剩余天数 /
                            365; (结果按四舍五入计算)
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>`,
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消"
            }
          ).catch(res => null);

          if (confirmRes === "confirm") {
            if (targetTerminalType.productType && money < deduct) {
              this.terminalType = sourceTerminalType.terminalType;
              return;
            }
          } else {
            this.terminalType = sourceTerminalType.terminalType;
          }

          // .then(() => {
          //   if (targetTerminalType.productType && money < deduct) {
          //     this.terminalType = sourceTerminalType.terminalType;
          //     return;
          //   }
          // })
          // .catch(action => {
          //   this.terminalType = sourceTerminalType.terminalType;
          // });

          // this.$Modal.confirm({
          //   cancelText: "取消",
          //   onOk: () => {
          //     if (targetTerminalType.productType && money < deduct) {
          //       this.terminalType = sourceTerminalType.terminalType;
          //       return;
          //     }
          //   },
          //   onCancel: () => {
          //     this.terminalType = sourceTerminalType.terminalType;
          //   },
          // });
        }
      }
      // this.renewChecked = false;
    },
    getVehiclePhotos() {
      getVehiclePhotos({ vehicleId: this.vehicleId }).then(res => {
        if (!res.flag) return;
        if (res.obj) {
          res.obj.forEach(p => {
            let item = this.vehiclePhotos.find(q => q.sort === p.sort);
            item.uuid = p.uuid;
            item.path = p.ftpServerPath + p.path;
          });
        }
      });
    },
    initVehicleInfo() {
      return {
        machineryEquipmentType: '', // 设备类型
        machineryProductType: '',  // 产品类型
        gasCard: "", //油卡号
        gasType: "", //加油型号
        gasTypegroupId: -1,
        vehicleId: 0,
        groupName: "", //车组名称
        plate: "", //设备名称
        terminalNo: "", //终端号
        terminalType: "GB-1078", //终端类型
        cameraLine: "", //摄像头
        cameraNum: 0, //摄像头数量
        sim: "", //SIM卡号
        datausage: 0, //SIM卡套餐（流量）
        iccid: "", //ICCID
        serviceCode: "000000", //服务密码
        renewalExpireDateStr: "", //服务到期
        expireDate: "", //车辆过期时间
        operator: "", //运营商
        roadPermit: "", //道路运输证号
        vehicleLicense: "", //行车证号码
        operatingTypes: "", //运营类型
        factoryNo: "GF03", //终端厂家型号
        // producerID: "", //终端厂家代码
        goods: "", //货物
        tripREC: "2012协议", //行车记录仪版本
        terminalIMEI: "", //   终端IMEI
        manufacturerId: "", // 制造商Id
        terminalId: "", // 终端id
        customNo: "", //自定义编号
        customMessage: "", // 自定义信息展示栏
        serviceProvider: "", //服务供应商
        salesman: "", //业务员
        recordPerson: "", //录入员
        company: "", //公司名称
        installDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //安装时间
        installPerson: "", //安装人员
        installPlace: "", //安装地点
        carhrough: "", //车籍地
        seat: "", //座位/吨位,
        nextReturnDueStr: "", //年审日期
        frameNo: "", //车架号
        brand: "", //车辆品牌
        plateColor: "黄色", //车牌颜色
        vehicleShape: "其他", //车辆类型
        peripheral: 0,
        percentageOfFlow: 0, //主动安全设备的协议,0: 苏标 1:佛山标准 2：河北标准，3：吉林标准 5：黑龙江标准
        engineNo: "", //发动机编号

        owner: "", //车主名称
        license: "", //行驶证号
        phone: "", //车主联系方式

        remark: "", //备注
        staypoint: "", //营运路线
        producerID: "",
        area: "",
        industry: "",
        ownerproperty: "",
        vehicleColor: "",
        vehiclestate: "",
        vehicleBrand: "",
        activeType: 0,
        gpsType: 1, // 设备类型

        containervolume: "", //货箱体积
        container: "", //货箱长宽高
        carstar: "", //车辆星标
        catage: "", //车辆新旧
        validitytime: "", //有效期
        flag: "", //1:渣土车 不传为其他车
        equipmentType: '智慧版'
      };
    },
    initExtraData() {
      return {
        ownerName: "", //业户名称
        ownerPhone: "", //业户电话
        area: "", // 所属区域
        unitAddress: "", //单位地址
        principalPhone: "", //负责人手机
        principalPlane: "", //负责人座机

        sali: "", //交强险金额
        dlw: "", //车损险金额
        ix: "", //车船使用税
        robStolenRisk: "", //盗抢险金额
        thirdPartyLiability: "", //第三者责任险
        liabilityInsurance: "", //车上人员责任险
        naturalRisk: "", //自然险金额
        insuranceDueDateStr: "" //保险到期时间
      };
    },
    initUnitInfo() {
      return {
        cellNo1: "", //负责人手机1
        cellNo2: "", //负责人手机2
        telNo1: "", //负责人座机1
        telNo2: "" //负责人座机2
      };
    },
    initPeripherals() {
      return [
        {
          value: 1 << 0,
          label: "主动安全防御终端（Adas+DSM）",
          checked: true,
          isHot: true
        },
        { value: 1 << 1, label: "油耗传感器", checked: true },
        { value: 1 << 2, label: "温度传感器", checked: true },
        { value: 1 << 3, label: "正反转传感器", checked: true },
        { value: 1 << 4, label: "载重传感器（空重载）", checked: true },
        { value: 1 << 5, label: "载重计量器", checked: true },
        { value: 1 << 6, label: "胎压传感器", checked: false },
        { value: 1 << 7, label: "振动传感器", checked: false }
      ];
    },
    initData() {
      this.vehicleInfo = this.initVehicleInfo();
      this.unitInfo = this.initUnitInfo();
      this.vehiclePhotos = this.initVehiclePhotos();
      this.extraData = this.initExtraData();
      this.peripherals = this.initPeripherals();
      this.terminalType = "GB-1078";
      this.vehiclePlace.province = "";
      this.vehiclePlace.city = "";
      this.vehiclePlace.district = "";
    },
    initAutoauth() {
      return {
        autoauth: "" //是否授权
      };
    },
    initVehicleAuthor() {
      return {
        authorUser: "",
        authortmonth: "",
        dateStr: "",
        groupName: "",
        groupid: "",
        id: "",
        plate: "",
        terminalNo: "",
        updatetime: "",
        username: "",
        vehicleId: ""
      };
    },
    // renewChange(val) {
    //   if (val) {
    //     this.autoauth = 1;
    //   } else {
    //     this.autoauth = 0;
    //   }
    // },
    changeCoin(val) {
      var coniForm = [];
      if (this.terminalType === "GPRS-部标") {
        coniForm = this.GPRSLength.filter(p => p.month === val * 1);
        this.coin = coniForm[0].coin;
      } else {
        coniForm = this.GFLength.filter(p => p.month === val * 1);
        this.coin = coniForm[0].coin;
      }
    },
    initVehiclePhotos() {
      let arr = [];
      for (let i = 0; i < 6; i++) {
        let obj = {
          sort: i + 1,
          uuid: "",
          path: "",
          file: "",
          type: [
            "车身照片",
            "车辆行驶证",
            "车辆登记证",
            "准运证开关",
            "货箱照片",
            "其他"
          ][i]
        };
        arr.push(obj);
      }
      return arr;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-title {
  background: #212d3e;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
}
.vehicle-info-content {
  height: 650px;
  font-size: 14px;
  overflow: auto;
  .content {
    overflow: auto;
    padding: 0 0 30px 0;
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    z-index: 55;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e8eaec;
    background: #fff;
    & > :first-child {
      margin-right: 5px;
      width: 80px;
    }
    & > :last-child {
      margin-left: 5px;
      width: 80px;
    }
  }

  .pane {
    margin: 10px;
    &:last-child {
      margin-bottom: 40px;
    }
  }

  .title {
    font-weight: bold;
    color: #2d8cf0;
  }
  .pane-content {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    & > ul > li {
      display: flex;
      margin-bottom: 10px;
    }
  }
  .item {
    margin: 1px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .item-cell {
    width: 100%;
    margin: 0 10px;
    height: 170px;
    &__photos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      & > div {
        height: 140px;
        margin: 2px;
        width: calc((100% - 16px) / 4);
      }
    }
  }
  .item-cell1 {
    width: 100%;
  }
  .item-cell2 {
    width: 50%;
  }
  .item-cell3 {
    width: 33%;
    margin: 9px 0;
  }

  .first-item {
    width: 105px;
    //   text-align: end;
    // white-space: nowrap;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }
  .required::before {
    content: '*';
    color: red;
    font-weight: bold;
    margin-right: 1px;
    font-size: 20px;
  }

  .last-item {
    position: relative;
    width: calc(100% - 105px);
    flex-grow: 1;
    margin-left: 10px;
    // border: 1px solid red;
    height: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .volume-item {
    position: relative;
    width: calc(100% - 250px);
    flex-grow: 1;
    height: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .container-item {
    position: relative;
    width: calc(100% - 88px);
    margin-left: 10px;
    flex-grow: 1;
    height: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }
  .containervolume-item {
    position: relative;
    width: calc(100% - 88px);
    margin-left: 10px;
    flex-grow: 1;
    height: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .select-item {
    width: 100%;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 12px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
    &:hover {
      border-color: #57a3f3;
    }
  }

  .vehicle-place-input {
    width: calc((100% - 10px) / 3);
    &:nth-of-type(2) {
      margin: 0 5px;
    }
  }
  .owner-item {
    & > div:first-child {
      margin-right: 2px;
    }
    & > div:last-child {
      margin-left: 2px;
    }
  }
  .service-expires {
    .el-tooltip-content {
      color: red;
    }
  }
  .car-group-dropdown {
    & > div:first-child {
      width: 100%;
    }
  }
  .datetime-input-item {
    & > div:first-child {
      width: 100%;
    }
  }
  .terminaltype-select {
  }
  .question-tip {
    box-sizing: border-box;
    cursor: pointer;
    width: 36px;
    font-size: 20px;
    padding-left: 5px;
  }
  .ed-content {
    & > div {
      border: 1px solid #2d8cf0;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 12px;
      margin-left: 51px;
      margin-bottom: 10px;
    }
    & > span {
      margin-left: 50px;
      color: red;
    }
  }
  .ed-checkbox {
    width: 250px;
    margin-bottom: 5px;
    .ed-label {
      display: flex;
    }
  }
  .flow-ratio {
    & > :first-child {
      width: calc(100% - 30px);
      margin-right: 5px;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .el-select-dropdown__item {
  .terminalTypeOption {
    display: flex;
    justify-content: space-between;
  }
}

::v-deep .el-dialog__header {
  padding: 0;
}

@media only screen and (max-width: 1366px) {
  .vehicle-info-content {
    height: 470px;
  }
}
::v-deep .el-select .el-input__inner {
  padding-right: 0px;
  padding-left: 4px;
}
</style>


