<template>
  <div class="RtpFooterWrapper">
    <el-tabs v-model="activeName"
      type="card">
      <el-tab-pane label="位置信息"
        name="first">
        <VirTable :data="dataList">
          <u-table-column type="index"
            width="60"
            label="序号" />
          <u-table-column prop="P"
            label="设备名称"
            width="180"></u-table-column>
          <u-table-column prop="N"
            label="终端号"
            width="180"></u-table-column>
          <u-table-column prop="groupName"
            label="所属车组"
            width="140"></u-table-column>
          <u-table-column prop="Z"
            label="车辆状态"
            width="160">
            <template v-slot="{row}">
              {{getZLabel(row.Z)}}
              <span v-if="row.D">{{row.D}}</span>
            </template>
          </u-table-column>
          <u-table-column prop="ACC"
            label="ACC"
            width="120">
            <template v-slot="{row}">{{getAccLabel(row.ACC)}}</template>
          </u-table-column>
          <u-table-column prop="S"
            label="速度(km/h)"
            width="120"></u-table-column>
          <u-table-column prop="L"
            label="里程(km)"
            width="120">
            <template v-slot="{row}">{{getLLabel(row.L)}}</template>
          </u-table-column>
          <u-table-column prop="G"
            label="定位"
            width="120">
            <template v-slot="{row}">{{getGLabel(row.G)}}</template>
          </u-table-column>
          <u-table-column prop="F"
            label="方向"
            width="120">
            <template v-slot="{row}">{{getFLabel(row.F)}}</template>
          </u-table-column>
          <u-table-column label="信号"
            width="120">
            <template v-slot="{row}">{{getGsmSinLabel(row.gsmSin)}}({{getNetModelLabel(row.netModel)}})</template>
          </u-table-column>
          <u-table-column prop="R"
            label="信号时间"
            width="160"></u-table-column>
          <u-table-column prop="PT"
            label="定位时间"
            width="160"></u-table-column>
          <u-table-column prop="pitchAngle"
            label="倾斜角度"
            width="160">
            <template v-slot="{row}">
              <span v-if="row.pitchAngle">{{row.pitchAngle}}°</span>
            </template>
          </u-table-column>
          <u-table-column prop="inclinationAngle"
            label="横滚角度"
            width="160">
            <template v-slot="{row}">
              <span v-if="row.inclinationAngle">{{row.inclinationAngle}}°</span>
            </template>
          </u-table-column>

          <u-table-column prop="address"
            label="地址"
            width="400">
            <template v-slot="{row}">
              <AddressText v-model="row.address"
                :lng="row.X"
                :lat="row.Y"
                :title="row.address"></AddressText>
            </template>
          </u-table-column>
        </VirTable>
      </el-tab-pane>
    </el-tabs>

    <div class="CountOuter">
      <span class="CountItem">
        <div class="is-dot"></div>
        <label>总数:</label>
        <label class="CountNum">{{countObj.vehicleTotal}}</label>
      </span>
      <span class="CountItem">
        <label>视频:</label>
        <label class="CountNum">{{countObj.gf1078}}</label>
      </span>
      <span class="CountItem">
        <label>定位:</label>
        <label class="CountNum">{{countObj.gprs}}</label>
      </span>
      <span class="CountItem">
        <div class="is-dot"></div>
        <label>在线:</label>
        <label class="CountNum">{{countObj.online}}</label>
      </span>
      <span class="CountItem">
        <div class="is-dot"></div>
        <label>在线率:</label>
        <label class="CountNum">
          {{
          Math.floor(countObj.online / countObj.vehicleTotal * 10000) / 100
          }}%
        </label>
      </span>
      <span class="CountItem">
        <label>行驶:</label>
        <label class="CountNum">{{countObj.run}}</label>
      </span>
      <span class="CountItem">
        <label>停车:</label>
        <label class="CountNum">{{countObj.stop}}</label>
      </span>
      <span class="CountItem">
        <label>过期:</label>
        <label class="CountNum">{{countObj.expire}}</label>
      </span>
      <span class="CountItem">
        <label>从未上线:</label>
        <label class="CountNum">{{countObj.notActive}}</label>
      </span>
    </div>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import { vehicleInfoCount } from '@/api/getHome';
import AddressText from '@/components/AddressText';
import { hasPerms, dataPermissions } from "@/utils/auth";


export default {
  name: 'RtpFooter',
  components: {
    AddressText,
  },
  props: {
    dataList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    this.timerId = null;
    return {
      activeName: 'first',
      // 统计数据
      countObj: {
        // 总数
        vehicleTotal: 0,
        // 视频
        gf1078: 0,
        // 部标
        gprs: 0,
        // 在线
        online: 0,
        // 在线率
        onlineRate: 0,
        // 行驶
        run: 0,
        // 停车
        stop: 0,
        // 离线
        offline: 0,
        // 过期
        expire: 0,
        // 从未上线
        notActive: 0,
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (hasPerms('M01')) {
      this.startStatistics();
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.timerId);
    this.timerId = null;
  },
  methods: {
    // 车辆状态
    getZLabel(Z) {
      if (Z === 0) return '怠速';
      if (Z === 1) return '行驶';
      if (Z === 2) return '作业';
      if (Z === 3) return '通电';
      if (Z === 4) return '熄火';
      if (Z === 5) return '离线';
      return "从未上线";
    },
    // 里程 km
    getLLabel(L) {
      return Math.floor(L / 1000 * 100) / 100;
    },
    // 定位
    getGLabel(G) {
      return G === 1 ? '卫星' : '不定位';
    },
    // ACC
    getAccLabel(ACC) {
      return ACC === 0 ? 'ACC开' : 'ACC关';
    },
    // 正反转
    getTurnDirLabel(turnDir) {
      if (turnDir === -1) return '停转';
      if (turnDir === 0) return '正转向';
      if (turnDir === 1) return '反转向';
      return '';
    },
    // 方向
    getFLabel(F) {
      if (F > 22.5 && F <= 67.5) return '东北';
      if (F > 67.5 && F <= 112.5) return '东';
      if (F > 112.5 && F <= 157.5) return '东南';
      if (F > 157.5 && F <= 202.5) return '南';
      if (F > 202.5 && F <= 247.5) return '西南';
      if (F > 247.5 && F <= 292.5) return '西';
      if (F > 292.5 && F <= 337.5) return '西北';
      if (F <= 22.5 || F > 337.5) return '北';
      return '';
    },
    // 信号状态
    getGsmSinLabel(gsmSin) {
      if (gsmSin >= 1 && gsmSin < 18) return '差';
      if (gsmSin >= 18 && gsmSin < 26) return '良';
      if (gsmSin >= 26) return '优';
    },
    // 网络制式
    getNetModelLabel(netModel) {
      if (netModel === 1) return '2G';
      if (netModel === 2) return '3G';
      if (netModel === 3) return '4G';
      return '';
    },

    startStatistics() {
      clearInterval(this.timerId);
      this.timerId = null;
      this.timerId = setInterval(() => this.timerId && this.getCount(), 1000 * 30);
      this.getCount();
    },

    async getCount() {
      try {
        const result = await vehicleInfoCount();

        if (result.flag !== 1) return;

        this.countObj = result.obj;

        // expire: 174
        // expireAndOnline: 13
        // gf1078: 715
        // gprs: 41
        // newCar: 0
        // notActive: 133
        // offline: 733
        // online: 23
        // onlineGf1078: 35
        // onlineGprs: 1
        // run: 10
        // stop: 26
        // vehicleTotal: 756

      } catch (err) {
        //
      }

    }

  }
}
</script>

<style lang="scss" scoped>
.RtpFooterWrapper {
  height: 100%;
  width: 100%;
  padding: 7px;
  background-color: #fff;
  position: relative;
}
::v-deep.el-tabs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .el-tabs__header {
    margin: 0 0 1px;
  }
  .el-tabs__content {
    flex: 1;
  }
  .el-tab-pane {
    height: 100%;
  }
}
.CountOuter {
  position: absolute;
  top: 10px;
  right: 6px;
  height: 32px;
  width: calc(100% - 140px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
  color: #2b2b2b;
}
.CountItem {
  margin-left: 15px;
  display: flex;
}
.CountNum {
  font-weight: bold;
  margin: 0 5px;
}
.is-dot {
  height: 8px;
  width: 8px;
  padding: 0;
  right: 0;
  border-radius: 50%;
  background-color: #f56c6c;
  margin: 2px 3px 0;
}
</style>