<template>
  <Dialog
    :value="dialogVisible"
    width="520px"
    height="300px"
    :submit="submitOk"
    @close="$emit('input',$event)"
  >
    <template #title>调度下发</template>
    <div v-if="vehicle" class="mainCls">
      <el-row>
        <el-col :span="12">
          <label>车牌:</label>
          <label>{{vehicle.plate}}</label>
        </el-col>
        <el-col :span="12">
          <label>终端号:</label>
          <label>{{vehicle.terminalNo}}</label>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-select v-model="textType">
            <el-option
              v-for="item in textTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-select v-model="downType">
            <el-option
              v-for="item in downTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-input
            v-model="downText"
            type="textarea"
            class="max-h"
            :rows="10"
            placeholder="请输入下发内容"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-col>
      </el-row>
    </div>
  </Dialog>
</template>

<script>
/**
 * 调度下发
 */

import { mapState } from 'vuex';
import Dialog from '@/components/Dialog';

import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';

/**
 * 下发类型
 */
const textTypeOptions = [
  { value: 1, label: '服务', flags: 'service' },
  { value: 2, label: '紧急', flags: 'emergency' },
  { value: 3, label: '通知', flags: 'notice' },
  { value: 4, label: '终端显示器显示', flags: 'terminalDisplay' },
  { value: 8, label: '终端TTS播报', flags: 'TTS' },
  { value: 16, label: '广告屏显示', flags: 'ADisplay' },
  { value: 0, label: '中心导航', flags: 'navigate' },
  { value: 32, label: 'CAN故障码', flags: 'CAN' }
];

/**
 * 文本类型
 */
const downTypeOptions = [
  { value: 1, label: '通知' },
  { value: 2, label: '服务' },
];

export default {
  name: 'CmdTextDown',
  components: {
    Dialog,
  },
  model: {
    prop: 'dialogVisible',
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    /**
     * 车辆对象
     * {
     * plate: 车牌, 
     * terminalNo: 终端号, 
     * vehicleId: 车辆Id, 
     * goupId: 车组Id
     * }
     */
    vehicle: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      downTypeOptions,
      textTypeOptions,
      downType: 1,
      textType: 1,
      downText: '',
    };
  },
  computed: {
    ...mapState(['userInfo']),
  },
  watch: {

  },
 
  methods: {
    async submitOk() {

      if (!cmdWsObj.request) throw new Error('命令链路未连接');

      const {
        vehicle,
        downText,
        textType,
        downType,
      } = this;

      if (!vehicle) return this.$message.warning('车辆信息为空');

      if (!downText) return this.$message.warning('请输入下发内容');

      const { label: downTypeLabel } = downTypeOptions.find(obj => obj.value === downType);
      const { flags, label: textTypeLabel } = textTypeOptions.find(obj => obj.value === textType);

      const spareMessage = [
        `【${textTypeLabel}】`,
        `【${downTypeLabel}】`,
        `【内容：${downText}】`,
      ].join('\r\n');

      const cmdData = {
        groupId: '',
        vehicleId: vehicle.vehicleId,
        commandType: 1000,
        param: {
          text: downText,
          textType: textType,
          flags: [flags],
          spareMessage,
        }
      };

      // TODO: 是否需要等待回复
      cmdWsObj
        .request
        .sendCmdAsync(CmdType.textDownloadSet, cmdData);

      this.$message.info('指令已下发');

      this.close();

      return true;
    },
    close() {
      this.$emit('input', false);
    }
  }
}
</script>

<style lang="scss" scoped>
.mainCls {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.el-row {
  margin: 10px 10px 0;
}
.max-h {
  ::v-deep .el-textarea__inner{
    max-height: 225px;
  }
}
</style>