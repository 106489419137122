<template>
  <el-radio-group class="FilterButton" size="medium" v-model="type">
    <el-radio-button :label="0" :style="{width: isCustomization ? '50%' : '25%'}">
      <div class="TypeItem">
        <span>全部</span>
        <span>({{total}})</span>
      </div>
    </el-radio-button>
    <el-radio-button :label="1" :style="{width: isCustomization ? '50%' : '25%'}">
      <div class="TypeItem">
        <span>在线</span>
        <span>({{online}})</span>
      </div>
    </el-radio-button>
    <el-radio-button :label="2" v-if="!isCustomization">
      <div class="TypeItem">
        <span>离线</span>
        <span>({{offline}})</span>
      </div>
    </el-radio-button>
    <el-radio-button :label="3" v-if="!isCustomization">
      <div class="TypeItem">
        <span>视频故障</span>
        <span>({{videoAlarm}})</span>
      </div>
    </el-radio-button>
  </el-radio-group>
</template>

<script>

export const FilterType = {
  isAll: 0,
  isOnline: 1,
  isOffline: 2,
  isVideoAlarm: 3,
};

export default {
  name: 'FilterButtom',
  model: {
    prop: 'filterType',
  },
  props: {
    filterType: {
      type: Number,
      default: FilterType.isAll,
    },
    total: {
      type: Number,
      default: 0,
    },
    online: {
      type: Number,
      default: 0,
    },
    offline: {
      type: Number,
      default: 0,
    },
    videoAlarm: {
      type: Number,
      default: 0
    },
    // 定制选项 默认全选车组
    isCustomization: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: this.isCustomization ? 1 : FilterType.isAll
    };
  },
  watch: {
    filterType(val) {
      this.type = val;
    },
    type(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style lang="scss" scoped>
.FilterButton {
  width: 100%;
}
.TypeItem {
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

::v-deep .el-radio-button {
  width: 25%;
  .el-radio-button__inner {
    width: 100%;
  }
}
</style>