<template>
  <el-dialog
    v-if="modal"
    :visible="modal"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    append-to-body
    @close="onModalChange(false)"
    width="1100px"
  >
    <div slot="title" class="header-title">
      <span>车辆资料</span>
    </div>
    <div class="vehicle-info-content">
      <div class="content">
        <div class="basic">
          <div class="title">基本信息</div>
          <div class="form">
            <div class="item">
              <div class="label required">设备名称：</div>
              <div class="info">
                <el-input readonly v-model.trim="vehicleInfo.plate"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label required">设备类型：</div>
              <div class="info">
                <el-select
                  class="row-selectAddress"
                  v-model="vehicleInfo.machineryProductType"
                  filterable
                  disabled
                  placeholder="设备类型"
                  clearable
                >
                  <el-option
                    v-for="item in machineryProductTypeList"
                    :key="item.dictType"
                    :label="item.dictName"
                    :value="item.dictType"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="item">
              <div class="label required">设备种类：</div>
              <div class="info">
                <el-select
                  class="row-selectAddress"
                  v-model="vehicleInfo.machineryEquipmentType"
                  filterable
                  disabled
                  placeholder="设备种类"
                  clearable
                >
                  <el-option
                    v-for="item in machineryEquipmentTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="item">
              <div class="label">型号：</div>
              <div class="info">
                <el-input
                  readonly
                  v-model.trim="vehicleInfo.machineryProductNum"
                ></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">品牌：</div>
              <div class="info">
                <el-select disabled v-model.trim="vehicleInfo.brand" clearable>
                  <el-option
                    :value="item.dictValue"
                    :label="item.dictLabel"
                    v-for="(item,index) in brandList"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="item">
              <div class="label">机身编号(车架号)：</div>
              <div class="info">
                <el-input readonly v-model.trim="vehicleInfo.frameNo"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">发动机编号：</div>
              <div class="info">
                <el-input readonly v-model.trim="vehicleInfo.engineNo"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label required">所属车组：</div>
              <div class="info car-group-dropdown">
                <el-input readonly v-model="groupStr" disabled>
                  <SelectGroupPopover @nodeClick="onConfirmClick" slot="append"></SelectGroupPopover>
                </el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">车牌号：</div>
              <div class="info">
                <el-input
                  readonly
                  v-model.trim="vehicleInfo.machineryProductName"
                ></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">出厂日期：</div>
              <div class="info">
                <el-date-picker
                  v-model="vehicleInfo.productionDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </div>
            </div>
            <div class="item">
              <div class="label">整机重量：</div>
              <div class="info">
                <el-input readonly v-model.number="vehicleInfo.seat"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">外形尺寸(mm)：</div>
              <div class="info">
                <el-input readonly v-model.trim="vehicleInfo.deviceSize"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">购买日期：</div>
              <div class="info">
                <el-date-picker
                  readonly
                  v-model="vehicleInfo.purchaseDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </div>
            </div>
            <div class="item">
              <div class="label">购买价格(元)：</div>
              <div class="info">
                <el-input readonly v-model.trim="vehicleInfo.purchasePrice"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">生产厂家：</div>
              <div class="info">
                <el-input
                  readonly
                  v-model.trim="vehicleInfo.manufactureFactory"
                ></el-input>
              </div>
            </div>
            <div class="item" style="width:100%">
              <div class="label">备注内容：</div>
              <div class="info" style="width:30%">
                <el-input
                  readonly
                  v-model="vehicleInfo.remark"
                  type="textarea"
                  :row="3"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="install">
          <div class="title">安装信息</div>
          <div class="form">
            <div class="item">
              <div class="label">安装工：</div>
              <div class="info">
                <el-input readonly v-model.trim="vehicleInfo.installPerson"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">安装时间：</div>
              <div class="info">
                <el-date-picker
                  readonly
                  v-model="vehicleInfo.installDate"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="安装时间"
                ></el-date-picker>
              </div>
            </div>
            <div class="item">
              <div class="label">工单编号：</div>
              <div class="info">
                <el-input readonly v-model="vehicleInfo.orderNumber"></el-input>
              </div>
            </div>
            <!-- <div class="item">
              <div class="label">激活时间：</div>
              <div class="info">
                <el-date-picker v-model="vehicleInfo.activationTme"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="激活时间">
                </el-date-picker>
              </div>
            </div>-->
          </div>
        </div>
        <div class="terminal">
          <div class="title">终端信息</div>
          <div class="form">
            <div class="item">
              <div class="label required">产品版本：</div>
              <div class="info">
                <el-select
                  disabled
                  v-model="vehicleInfo.curTerminal"
                  placeholder="请选择"
                  clearable
                  value-key="label"
                >
                  <el-option
                    v-for="(item) in terminalList"
                    :key="item.label"
                    :label="item.label"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div
              class="item"
              v-if="vehicleInfo.curTerminal && vehicleInfo.equipmentType.indexOf('智慧') === 0"
            >
              <div class="label required">摄像头：</div>
              <div class="info">
                <el-select
                  v-model="vehicleInfo.cameraLine"
                  multiple
                  disabled
                  class="camera-select"
                >
                  <el-option
                    v-for="camera in 12"
                    :key="camera"
                    :value="camera"
                    :label="camera"
                  >摄像头{{ camera }}</el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="terminal-info" v-if="vehicleInfo.curTerminal">
            <div
              class="info-item"
              v-for="item,index in vehicleInfo.vehicleTerminals"
              :key="index"
            >
              <div class="title">
                <span v-if="item.deviceType === 1">视频终端</span>
                <span v-if="item.deviceType === 2">OBD终端</span>
                <span v-if="item.deviceType === 3">有线定位器</span>
                <span v-if="item.deviceType === 4">无线定位器</span>
                <el-tooltip
                  class="question-tip"
                  trigger="hover"
                  placement="top-start"
                >
                  <div class="el-tooltip-content" slot="content">
                    <div>1、视频类转部标类：成功后剩余到期时间将无法退币</div>
                    <div>2、部标类转视频类：成功后将扣除您对应的授权币数，后续续费将按视频类设备收费</div>
                    <div>3、部标类转视频类：如果当前账号余额不足，无法转换为视频类设备</div>
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <span class="host" v-if="index === 0">主终端</span>
              </div>
              <div class="form">
                <div class="item">
                  <div class="label required">终端编号：</div>
                  <div class="info">
                    <el-input readonly v-model="item.terminalNo"></el-input>
                  </div>
                </div>
                <div class="item">
                  <div class="label required">SIM卡号：</div>
                  <div class="info">
                    <el-input readonly v-model="item.terminalSim"></el-input>
                  </div>
                </div>
                <!-- <div class="item">
                  <div class="label">工单编号：</div>
                  <div class="info">
                    <el-input readonly v-model="item.orderNumber"></el-input>
                  </div>
                </div>-->
                <div class="item">
                  <div class="label">流量(GB)：</div>
                  <div class="info">
                    <el-input readonly v-model="item.datausage"></el-input>
                  </div>
                </div>
                <div class="item">
                  <div class="label">厂商型号：</div>
                  <div class="info">
                    <el-input readonly v-model="item.manufactorType"></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="owner">
          <div class="title">车主信息</div>
          <div class="form">
            <div class="item">
              <div class="label">车主姓名：</div>
              <div class="info">
                <el-input readonly v-model.trim="vehicleInfo.owner"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">联系电话：</div>
              <div class="info">
                <el-input readonly v-model.number="vehicleInfo.phone"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="label">紧急联系电话：</div>
              <div class="info">
                <el-input readonly v-model.number="vehicleInfo.license"></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import "@/js/directives.js"; //拖拽功能JS
import { mapState } from "vuex";
import { DistrictSearchOptions as getDistrict } from "@/api/util-api";
import moment from 'dayjs';
import InputQuery from "@/components/SearchVehicleGroup";
import SelectGroupPopover from "@/components/SelectGroupPopover";//1
import {
  getVehicleInfo,
  directAddVehicle,
  updateVehicle
} from "@/api/live-vehicle-api";
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";

import { queryMyState } from "@/api/getManagementRenew";
import { hasPerms } from "@/utils/auth";


import { getProductTypeIconName } from '@/utils/getProductTypeIconName';
import { getBrandList } from "@/api/getData";


export default {
  name: "ModalVehicleInfo",
  components: {
    InputQuery,
    SelectGroupPopover
  },
  model: {
    prop: "modal",
    event: "change"
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    vehicleId: {
      type: Number,
      default: 0
    },
    groupId: {
      type: Number,
      default: -1,
      required: false
    },
    groupName: {
      type: String,
      default: "",
      required: false
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    let id = 0;
    return {

      machineryProductTypeList: [],
      machineryEquipmentTypeList: [],
      groupStr: "",
      cameras: [1, 2, 3, 4],
      provinces: [],
      activeNames: [" "],
      vehiclePhotos: [],
      IS_zhatuche_TYPE: false,
      vehiclePlace: {
        cities: [],
        districts: [],
        province: "",
        city: "",
        district: ""
      },
      terminalType: "GB-1078",
      vehicleInfo: this.initVehicleInfo(),
      unitInfo: this.initUnitInfo(),
      extraData: this.initExtraData(),
      vehicleAuthorVO: this.initVehicleAuthor(),
      terminalTypes: [
        { terminalType: "GPRS-部标", productType: 0, coinPer: 10 },
        // { terminalType: "GF-1078", productType: 1, coinPer: 25 },
        { terminalType: "GB-1078", productType: 1, coinPer: 25 },
        // { terminalType: "GA系列", productType: 1, coinPer: 25 }
      ],
      plateColors: [
        //车牌颜色
        "蓝色",
        "黄色",
        "绿色",
        "农黄色",
        "农绿色",
        "黄绿色",
        "渐变绿",
        "其他"
      ],
      vehicleColors: [
        //车辆颜色
        "红色",
        "蓝色",
        "黑色",
        "白色",
        "黄色",
        "绿色"
      ],
      carstarOptions: [
        // 车辆星标
        "普通车",
        "星标车辆",
        "重点星标"
      ],
      transformTypes: [
        "道路旅客运输",
        "班线客运",
        "道路货物运输",
        "道路危险货物运输",
        "机动车维修",
        "机动车驾驶员培训",
        "站场服务",
        "国际道路运输",
        "公交运输",
        "出租运输",
        "汽车租赁",
        "包车客运",
        "定线旅游",
        "班车客运",
        "其他"
      ],
      containerOptions: [
        {
          len: "",
          placeholder: "长/m"
        },
        {
          len: "",
          placeholder: "宽/m"
        },
        {
          len: "",
          placeholder: "高/m"
        }
      ], //长宽高
      peripherals: this.initPeripherals(),
      adasProtocolOptions: [
        { value: 0, label: "苏标" },
        { value: 4, label: "沪标" },
        { value: 1, label: "佛山标准" },
        { value: 2, label: "河北标准" },
        { value: 3, label: "吉林标准" },
        { value: 5, label: "黑龙江标准" },
        { value: 6, label: "粤标" },
        { value: 7, label: "陕西标准" },
        { value: 8, label: "川标" }
      ],

      //部标
      GPRSLength: [
        {
          month: 3,
          coin: 3
        },
        {
          month: 6,
          coin: 6
        },
        {
          month: 12,
          coin: 10
        },
        {
          month: 36,
          coin: 30
        },
        {
          month: 60,
          coin: 50
        }
      ],
      //视频
      GFLength: [
        {
          month: 3,
          coin: 10
        },
        {
          month: 6,
          coin: 20
        },
        {
          month: 12,
          coin: 25
        },
        {
          month: 36,
          coin: 75
        },
        {
          month: 60,
          coin: 125
        }
      ],
      coin: 0,
      autoauth: "", //是否自动授权
      VehicleAuthor: {}, // 续费时长
      renewChecked: "",
      coniForm: [],
      Volume_Static: 0,
      // 品牌列表
      brandList: [],
      terminalList: [
        { label: '智慧版mini', children: [{ terminalSim: "", terminalNo: "", deviceType: 1, mainFlag: true, terminalType: "GB-1078", type: 'video', label: '视频终端', datausage: '', manufactorType: '', unit: 'GB' }] },
        { label: '智慧版', children: [{ terminalSim: "", terminalNo: "", deviceType: 1, mainFlag: true, terminalType: "GB-1078", type: 'video', label: '视频终端', datausage: '', manufactorType: '', unit: 'GB' }, { terminalSim: "", terminalNo: "", deviceType: 2, mainFlag: false, type: 'OBD', terminalType: "GPRS-部标", label: 'OBD终端', datausage: '', manufactorType: '', unit: "GB" }] },
        { label: '智慧版plus', children: [{ terminalSim: "", terminalNo: "", deviceType: 1, mainFlag: true, terminalType: "GB-1078", type: 'video', label: '视频终端', datausage: '', manufactorType: '', unit: 'GB' }, { terminalSim: "", terminalNo: "", deviceType: 2, mainFlag: false, type: 'OBD', terminalType: "GPRS-部标", label: 'OBD终端', datausage: '', manufactorType: '', unit: "GB" }] },
        { label: '鹰眼版', children: [{ terminalSim: "", terminalNo: "", deviceType: 3, mainFlag: true, terminalType: "GPRS-部标", type: 'wired', label: '有线定位器', datausage: '', manufactorType: '', unit: 'MB' }, { terminalSim: "", terminalNo: "", deviceType: 4, mainFlag: false, type: 'wireless', terminalType: "GPRS-部标", label: '无线定位器', datausage: '', manufactorType: '', unit: "MB" }, { terminalSim: "", terminalNo: "", deviceType: 4, mainFlag: false, type: 'wireless', terminalType: "GPRS-部标", label: '无线定位器', datausage: '', manufactorType: '', unit: "MB" }] },
        { label: '效能版', children: [{ terminalSim: "", terminalNo: "", deviceType: 2, mainFlag: true, terminalType: "GPRS-部标", type: 'OBD', label: 'OBD终端', datausage: '', manufactorType: '', unit: "GB" }] }
      ],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    //修改资料权限
    isEditVehicleInfo() {
      return true;
      // return bsjconfig.isEditVehicleInfo;
    },
    isTmpOrMonitorUser() {
      return this.isMonitorUser;
    },
    isWrite() {
      return true;
      return (
        (!this.vehicleId && this.hasPerms("M07SM02F04")) ||
        (this.vehicleId && this.hasPerms("M07SM02F05"))
      );
    },
    is1078() {
      return ["GF-1078", "GB-1078"].includes(this.terminalType);
    },
    IS_CL211() {
      return true;
      return (
        bsjconfig.customerCodes && bsjconfig.customerCodes.includes("CL211")
      );
    }
  },
  watch: {
    modal: {
      immediate: true,
      handler: function (val) {
        this.initData();
        if (!val) return;

        // this.renewChecked = false;
        this.vehicleInfo.groupId = this.groupId;
        this.vehicleInfo.groupName = this.groupName;
        this.groupStr = this.groupName;
        if (!this.vehicleId) return;

        this.getVehicleInfoByid(this.vehicleId);

      }
    },
    "vehicleInfo.vehicleShape": {
      handler: function (val) {
        if (val === "渣土车") {
          this.IS_zhatuche_TYPE = true;
          this.vehicleInfo.flag = "1";
        } else {
          this.IS_zhatuche_TYPE = false;
          this.vehicleInfo.flag = "";
        }
      }
    },
    Volume_Static: {
      handler: function (val) {
        if (val === 0) {
          for (let i = 0; i < this.containerOptions.length; i++) {
            this.containerOptions[i].len = "";
          }
          this.vehicleInfo.container = "";
        } else {
          this.vehicleInfo.containervolume = "";
        }
      }
    }
  },
  mounted() {
    this.getMachineryProductTypeList();
    this.getBrandList();

    if (this.vehicleId * 1 === 0) {
      this.VehicleAuthor.authoruser = this.userInfo.userId;
    }
  },
  beforeMount() {
    getDistrict().then(res => {
      this.provinces = res;
    });
  },
  methods: {
    getProductTypeIconName,
    hasPerms,
    // 获取产品类型列表
    getMachineryProductTypeList() {
      getDictList({ type: '1' }).then(res => {
        if (res.flag === 1) {
          this.machineryProductTypeList = res.rows;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    getBrandList() {
      getBrandList({
        dictType: 'vehicle_brand',
        platform: 'PC'
      }).then(res => {
        if (res.flag === 1) {
          this.brandList = res.obj;
        }
      });
    },


    // 根据车辆id获取车辆信息
    async getVehicleInfoByid(vehicleId) {


      try {
        const res = await getVehicleInfo(vehicleId);


        this.vehicleInfo = res.obj.dvehicleInfo;
        let temp = this.terminalList.find(ele => {
          return ele.label === res.obj.dvehicleInfo.equipmentType;
        });
        // console.log(temp);
        // this.vehicleInfo.curTerminal = temp || this.terminalList[1];
        // //   this.vehicleInfo.vehicleTerminals = temp.children;
        // this.vehicleInfo.vehicleTerminals = res.obj.VehicleTerminals;
        this.vehicleInfo.curTerminal = temp || this.terminalList[1];
        console.log(this.vehicleInfo.curTerminal.children);
        //   this.vehicleInfo.vehicleTerminals = temp.children;
        this.vehicleInfo.vehicleTerminals = (res.obj.VehicleTerminals && res.obj.VehicleTerminals.length ? Object.assign(this.vehicleInfo.curTerminal.children, res.obj.VehicleTerminals) : JSON.parse(JSON.stringify(this.vehicleInfo.curTerminal.children)));
        this.groupStr = res.obj.dvehicleInfo.groupName;
        this.getMachineryEquipmentTypeList();
        if (this.vehicleInfo.cameraLine) this.vehicleInfo.cameraLine = JSON.parse(this.vehicleInfo.cameraLine);
        if (this.vehicleInfo.productionDate) this.vehicleInfo.productionDate = new Date(this.vehicleInfo.productionDate).toLocaleDateString().replace(/\//g, '-');
        if (this.vehicleInfo.purchaseDate) this.vehicleInfo.purchaseDate = new Date(this.vehicleInfo.purchaseDate).toLocaleDateString().replace(/\//g, '-');
        if (this.vehicleInfo.activationTmeStr) this.vehicleInfo.activationTme = this.vehicleInfo.activationTmeStr;
        this.$forceUpdate();
      } catch (error) {
        this.$message.warning('查询异常');
        console.log(error);
      }
    },

    // 获取设备类型列表
    getMachineryEquipmentTypeList() {

      getDictType({ dictType: this.vehicleInfo.machineryProductType }).then(res => {
        if (res.flag === 1) {
          this.machineryEquipmentTypeList = res.obj;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 选择产品类型
    selectMachineryProductType() {
      this.getMachineryEquipmentTypeList();
    },
    // 选择设备类型
    selectMachineryEquipmentType() {

    },
    //判断是否能编辑
    handleDisabled() {
      return this.isEditVehicleInfo && !this.isWrite
        ? this.isEditVehicleInfo
        : this.isTmpOrMonitorUser || !this.isEdit;
    },
    terminalNoSet() {
      this.vehicleInfo.terminalNo = this.vehicleInfo.terminalNo.replace(
        /[^0-9]/g,
        ""
      );
      if (
        this.vehicleInfo.terminalNo.length > 11 &&
        !this.vehicleInfo.manufacturerId.length
      ) {
        this.vehicleInfo.manufacturerId = "000070111";
      }
    },
    onModalChange(modal, eventName = "on-cancel", ...options) {
      this.groupStr = "";
      this.$emit("change", modal);
      this.$emit(eventName, ...options);
    },
    validateData() {
      let {
        plate,
        terminalNo,
        terminalIMEI,
        terminalId,
        sim,
        groupName,
        manufacturerId,
        groupId,
        vehicleColor,
        container,
        containervolume,
        machineryEquipmentType,
        machineryProductType,
        gpsType,
        vehicleBrand
      } = this.vehicleInfo;

      let singList = ["!", "@", "#", "￥", "%", "……", "&", "*"];
      if (singList.some(res => plate.includes(res))) {
        this.$message({
          type: 'warning',
          message: "设备名称不能带特殊符号"
        });
        return false;
      }

      // if (['GPRS-部标'].includes(this.terminalType)) {
      //   if (!gpsType) {
      //     this.$message({
      //       type: 'warning',
      //       message: '请选择设备类型'
      //     });
      //     return false;
      //   }
      // }
      if (!machineryProductType) {
        this.$message({
          type: 'warning',
          message: '请选择产品类型'
        });
        return false;
      }
      if (!machineryEquipmentType) {
        this.$message({
          type: 'warning',
          message: '请选择设备类型'
        });
        return false;
      }
      if (!vehicleBrand) {
        this.$message({
          type: 'warning',
          message: '请选择车辆品牌'
        });
        return false;
      }



      //判断是否为渣土车类型
      if (this.IS_zhatuche_TYPE) {
        if (
          !containervolume &&
          this.containerOptions.find(p => p.len === "") !== undefined
        ) {
          this.$message({
            type: 'warning',
            message: "货箱体积请填写完整"
          });
          return false;
        }

        if (!vehicleColor) {
          this.$message({
            type: 'warning',
            message: '车辆颜色不能为空'
          });
          return false;
        }
        if (containervolume === "") {
          //拼接长宽高
          this.vehicleInfo.container = "";
          this.containerOptions.map((item, index) => {
            if (index < this.containerOptions.length - 1) {
              this.vehicleInfo.container += item.len + ",";
            } else {
              this.vehicleInfo.container += item.len;
            }
          });
        }
      }
      if (plate.length < 3) {
        this.$message({
          type: 'warning',
          message: '设备名称最少3位'
        });
        return false;
      }

      if ((terminalNo + "").length !== 11 && (terminalNo + "").length !== 20) {
        this.$message({
          type: 'warning',
          message: '终端编号必须11位或20位纯数字'
        });
        return false;
      }
      if (terminalNo.toString().length === 20) {
        if (!this.vehiclePlace.province.length) {
          this.$message({
            type: 'warning',
            message: '安装信息-车籍地不能为空'
          });
          return false;
        }
        if (!terminalIMEI) {
          this.$message({
            type: 'warning',
            message: '终端IMEI号不能为空'
          });
          return false;
        }
        if (!terminalId) {
          this.$message({
            type: 'warning',
            message: '终端ID不能为空'
          });
          return false;
        }
        if (!manufacturerId) {
          this.$message({
            type: 'warning',
            message: '制造商ID不能为空'
          });
          return false;
        }
      }
      if (![11, 13].includes((sim + "").length)) {
        this.$message({
          type: 'warning',
          message: 'SIM卡号必须11位纯数字'
        });
        return false;
      }
      if (!(groupName.length && groupId > -1)) {
        this.$message({
          type: 'warning',
          message: '请选择有效车组'
        });
        return false;
      }
      if (!this.cameras.length) {
        this.$message.warning('摄像头不能为空');
        return false;
      }

      if (
        [
          (this.extraData.sali + "").length > 8,
          (this.extraData.dlw + "").length > 8,
          (this.extraData.ix + "").length > 8,
          (this.extraData.robStolenRisk + "").length > 8,
          (this.extraData.thirdPartyLiability + "").length > 8,
          (this.extraData.liabilityInsurance + "").length > 8,
          (this.extraData.naturalRisk + "").length > 8
        ].includes(true)
      ) {
        this.$message({
          type: 'warning',
          message: '保险金额必须小于8位纯数字'
        });
        return;
      }
      return true;
    },
    onSaveData() {
      if (!this.validateData()) return;
      if (!this.vehicleInfo.vehicleShape)
        this.vehicleInfo.vehicleShape = "其他";
      // this.vehicleInfo.terminalType = this.terminalType;
      this.vehicleInfo.carhrough = `${ this.vehiclePlace.province },${ this.vehiclePlace.city },${ this.vehiclePlace.district }`;
      this.vehicleInfo.cameraLine = JSON.stringify(
        this.cameras.sort((a, b) => a - b)
      );
      this.vehicleInfo.cameraNum = this.cameras.length;
      this.vehicleInfo.peripheral = this.peripherals.reduce(
        (acc, p) => (p.checked ? (acc += p.value) : acc),
        0
      );
      // if (this.renewChecked) {
      //   if (this.VehicleAuthor.authortmonth === "") {
      //     this.$message({
      //       type: 'warning',
      //       message: '请选择续费时长'
      //     });
      //     return;
      //   }
      // }

      this.extraData.principalPhone = `${ this.unitInfo.cellNo1 },${ this.unitInfo.cellNo2 }`;
      this.extraData.principalPlane = `${ this.unitInfo.telNo1 },${ this.unitInfo.telNo2 }`;
      this.vehicleInfo.gasCard = this.vehicleInfo.gasCard ? this.vehicleInfo.gasCard.replace(/，/g, ",") : '';//油卡号中文逗号替换为英文逗号

      let action = [directAddVehicle, updateVehicle][
        ~~!!this.vehicleInfo.vehicleId
      ];

      let uuid = "";
      const addFiles = this.vehiclePhotos.filter(p => !p.uuid && p.file);
      const upFiles = this.vehiclePhotos.filter(p => {
        if (p.uuid) uuid = p.uuid;
        return p.uuid && p.file;
      });
      action(
        this.vehicleInfo,
        this.extraData,
        this.VehicleAuthor,
        this.autoauth
      ).then(res => {
        if (res.msg) this.$message({
          type: 'success',
          message: res.msg
        });
        if (res.flag) {
          const upParams = {
            uuid: upFiles.filter(p => p.file).map(p => p.uuid)[0],
            file: upFiles.map(p => p.file),
            sorts: upFiles.map(p => p.sort)
          };

          const addParams = {
            uuid,
            vehicleId:
              this.vehicleInfo.vehicleId === 0
                ? res.obj.vehicleId
                : this.vehicleInfo.vehicleId,
            file: addFiles.map(p => p.file),
            sorts: addFiles.map(p => p.sort)
          };
          if (upFiles.length) updateVehiclePhotos(upParams);
          if (addFiles.length) uploadVehiclePhotos(addParams);
          this.$store.getVehiclesAgain = true;
          this.onModalChange(false, "on-ok", {
            vehicle: this.vehicleInfo,
            extraData: this.extraData,
            VehicleAuthor: this.VehicleAuthor,
            autoauth: this.autoauth
          });

        }
      });
    },
    onChangeProvinceVehiclePlace(value) {
      const province = this.provinces.find(p => p.name === value);
      if (!province) return;
      getDistrict(province.adcode, 1).then(res => {
        this.vehiclePlace.cities = res;
        if (!res.some(p => p.name === this.vehiclePlace.city)) {
          this.vehiclePlace.city = res.length ? res[0].name : "";
          this.vehiclePlace.district = "";
        }
        this.onChangeCityVehiclePlace(this.vehiclePlace.city);
      });
    },
    onChangeCityVehiclePlace(value) {
      const city = this.vehiclePlace.cities.find(p => p.name === value);
      if (!city) return;
      getDistrict(city.adcode, 2).then(res => {
        this.vehiclePlace.districts = res;
        if (!res.some(p => p.name === this.vehiclePlace.district)) {
          this.vehiclePlace.district = res.length ? res[0].name : "";
        }
      });
    },
    onConfirmClick(group) {
      const { groupId, groupName, groupType } = group;
      this.vehicleInfo.groupId = ~~groupId;
      this.vehicleInfo.groupName = groupName;
      this.groupStr = groupName;
      if (!this.vehicleInfo.vehicleShape)
        this.vehicleInfo.vehicleShape = groupType || "其他";
    },
    onSelectQuery(item) {
      const { vehicleId, groupId, plate, groupName, type, groupType } = item;
      this.vehicleInfo.groupId = ~~groupId;
      this.vehicleInfo.groupName = groupName;
      if (!this.vehicleInfo.vehicleShape)
        this.vehicleInfo.vehicleShape = groupType || "其他";
    },
    onSelectPhoto(files, extra) {
      extra.file = files;
    },
    async onChangeTerminalType(value) {
      let { vehicleId, terminalType, expireDate } = this.vehicleInfo;
      // let targetTerminalType = this.terminalTypes.find(
      //   p => p.terminalType === value
      // );
      // let sourceTerminalType = this.terminalTypes.find(
      //   p => p.terminalType === terminalType
      // );

      //
      if (
        vehicleId &&
        expireDate &&
        sourceTerminalType.productType !== targetTerminalType.productType
      ) {
        let remainDay =
          (new Date(expireDate).getTime() - new Date().getTime()) /
          1000 /
          60 /
          60 /
          24 +
          1;
        remainDay = remainDay < 0 ? 0 : remainDay;
        if (!remainDay) return; //已过期
        let productTypes = ["部标类", "视频类"];
        let tips = {
          0: "视频类转部标类，成功后剩余到期时间将无法退币",
          1: "部标类转视频类，成功后将扣除您对应的授权币数，后续续费将按视频类设备收费",
          2: "部标类转视频类，当前账号余额不足，无法转换为视频类设备"
        };

        let result = await queryMyState();
        let {
          flag,
          obj: {
            money: { name, money }
          }
        } = result;
        if (result.flag) {
          let tip = tips[0];
          let deduct; // 应扣除授权币
          if (targetTerminalType.productType === 1) {
            tip = tips[1];
            deduct = Math.round((15 * remainDay) / 365);
            if (money < deduct) tip = tips[2];
          }
          const confirmRes = await this.$alert(
            `<div style="position: relative;color:#79808f;font-size:13px;">
                  <ul>
                    <li>用户名：${ name }</li>
                    <li>
                      授权币：<span style="color:#ff4444;">${ money }</span>
                    </li>
                    <li>
                      过期时间：
                      <span style="color:#ff4444;">
                        ${ moment(new Date(expireDate)).format("YYYY-MM-DD") }
                      </span>
                    </li>
                    <li>
                      剩余天数：
                      <span style="color:#ff4444;">
                        ${ Math.round(remainDay) }
                      </span>
                      &nbsp;天
                    </li>
                    <li>
                      操作说明：
                      <span style="color:#ff4444;">
                        ${ productTypes[sourceTerminalType.productType] }（
                        ${ sourceTerminalType.terminalType }）
                      </span>
                      &nbsp;&nbsp;转&nbsp;&nbsp;
                      <span style="color:#ff4444;">
                        ${ productTypes[targetTerminalType.productType] }（
                        ${ targetTerminalType.terminalType }）
                      </span>
                    </li>
                    <li style="display: ${ ["none", ""][targetTerminalType.productType]
            };">
                      应扣除授权币：<span style="color:#ff4444;">${ deduct }</span>
                    </li>
                    <li style="color:#2d8cf0">
                      温馨提示！
                      <ul>
                        <li>
                          1、<span>${ tip };</span>
                        </li>
                        <li>
                          2、<span>如有疑问请联系商务;</span>
                        </li>
                        <li style=${ { display: ["none", ""][0] } }>
                          3、
                          <span>
                            扣除授权币 = 15 * 剩余天数 /
                            365; (结果按四舍五入计算)
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>`,
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消"
            }
          ).catch(res => null);

          if (confirmRes === "confirm") {
            if (targetTerminalType.productType && money < deduct) {
              this.terminalType = sourceTerminalType.terminalType;
              return;
            }
          } else {
            this.terminalType = sourceTerminalType.terminalType;
          }

          // .then(() => {
          //   if (targetTerminalType.productType && money < deduct) {
          //     this.terminalType = sourceTerminalType.terminalType;
          //     return;
          //   }
          // })
          // .catch(action => {
          //   this.terminalType = sourceTerminalType.terminalType;
          // });

          // this.$Modal.confirm({
          //   cancelText: "取消",
          //   onOk: () => {
          //     if (targetTerminalType.productType && money < deduct) {
          //       this.terminalType = sourceTerminalType.terminalType;
          //       return;
          //     }
          //   },
          //   onCancel: () => {
          //     this.terminalType = sourceTerminalType.terminalType;
          //   },
          // });
        }
      }
      // this.renewChecked = false;
    },
    getVehiclePhotos() {
      getVehiclePhotos({ vehicleId: this.vehicleId }).then(res => {
        if (!res.flag) return;
        if (res.obj) {
          res.obj.forEach(p => {
            let item = this.vehiclePhotos.find(q => q.sort === p.sort);
            item.uuid = p.uuid;
            item.path = p.ftpServerPath + p.path;
          });
        }
      });
    },
    initVehicleInfo() {
      return {
        machineryEquipmentType: '', // 设备类型
        machineryProductType: '',  // 产品类型
        gasCard: "", //油卡号
        gasType: "", //加油型号
        gasTypegroupId: -1,
        vehicleId: 0,
        groupName: "", //车组名称
        plate: "", //设备名称
        terminalNo: "", //终端号
        terminalType: "GB-1078", //终端类型
        cameraLine: "", //摄像头
        cameraNum: 0, //摄像头数量
        sim: "", //SIM卡号
        datausage: 0, //SIM卡套餐（流量）
        iccid: "", //ICCID
        serviceCode: "000000", //服务密码
        renewalExpireDateStr: "", //服务到期
        expireDate: "", //车辆过期时间
        operator: "", //运营商
        roadPermit: "", //道路运输证号
        vehicleLicense: "", //行车证号码
        operatingTypes: "", //运营类型
        factoryNo: "GF03", //终端厂家型号
        // producerID: "", //终端厂家代码
        goods: "", //货物
        tripREC: "2012协议", //行车记录仪版本
        terminalIMEI: "", //   终端IMEI
        manufacturerId: "", // 制造商Id
        terminalId: "", // 终端id
        customNo: "", //自定义编号
        customMessage: "", // 自定义信息展示栏
        serviceProvider: "", //服务供应商
        salesman: "", //业务员
        recordPerson: "", //录入员
        company: "", //公司名称
        installDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //安装时间
        installPerson: "", //安装人员
        installPlace: "", //安装地点
        carhrough: "", //车籍地
        seat: "", //座位/吨位,
        nextReturnDueStr: "", //年审日期
        frameNo: "", //车架号
        brand: "", //车辆品牌
        plateColor: "黄色", //车牌颜色
        vehicleShape: "其他", //车辆类型
        peripheral: 0,
        percentageOfFlow: 0, //主动安全设备的协议,0: 苏标 1:佛山标准 2：河北标准，3：吉林标准 5：黑龙江标准
        engineNo: "", //发动机编号

        owner: "", //车主名称
        license: "", //行驶证号
        phone: "", //车主联系方式

        remark: "", //备注
        staypoint: "", //营运路线
        producerID: "",
        area: "",
        industry: "",
        ownerproperty: "",
        vehicleColor: "",
        vehiclestate: "",
        vehicleBrand: "",
        activeType: 0,
        gpsType: 1, // 设备类型

        containervolume: "", //货箱体积
        container: "", //货箱长宽高
        carstar: "", //车辆星标
        catage: "", //车辆新旧
        validitytime: "", //有效期
        flag: "", //1:渣土车 不传为其他车
        orderNumber: '', //工单编号
      };
    },
    initExtraData() {
      return {
        ownerName: "", //业户名称
        ownerPhone: "", //业户电话
        area: "", // 所属区域
        unitAddress: "", //单位地址
        principalPhone: "", //负责人手机
        principalPlane: "", //负责人座机

        sali: "", //交强险金额
        dlw: "", //车损险金额
        ix: "", //车船使用税
        robStolenRisk: "", //盗抢险金额
        thirdPartyLiability: "", //第三者责任险
        liabilityInsurance: "", //车上人员责任险
        naturalRisk: "", //自然险金额
        insuranceDueDateStr: "" //保险到期时间
      };
    },
    initUnitInfo() {
      return {
        cellNo1: "", //负责人手机1
        cellNo2: "", //负责人手机2
        telNo1: "", //负责人座机1
        telNo2: "" //负责人座机2
      };
    },
    initPeripherals() {
      return [
        {
          value: 1 << 0,
          label: "主动安全防御终端（Adas+DSM）",
          checked: true,
          isHot: true
        },
        { value: 1 << 1, label: "油耗传感器", checked: true },
        { value: 1 << 2, label: "温度传感器", checked: true },
        { value: 1 << 3, label: "正反转传感器", checked: true },
        { value: 1 << 4, label: "载重传感器（空重载）", checked: true },
        { value: 1 << 5, label: "载重计量器", checked: true },
        { value: 1 << 6, label: "胎压传感器", checked: false },
        { value: 1 << 7, label: "振动传感器", checked: false }
      ];
    },
    initData() {
      this.vehicleInfo = this.initVehicleInfo();
      this.unitInfo = this.initUnitInfo();
      this.vehiclePhotos = this.initVehiclePhotos();
      this.extraData = this.initExtraData();
      this.peripherals = this.initPeripherals();
      this.terminalType = "GB-1078";
      this.vehiclePlace.province = "";
      this.vehiclePlace.city = "";
      this.vehiclePlace.district = "";
    },
    initAutoauth() {
      return {
        autoauth: "" //是否授权
      };
    },
    initVehicleAuthor() {
      return {
        authorUser: "",
        authortmonth: "",
        dateStr: "",
        groupName: "",
        groupid: "",
        id: "",
        plate: "",
        terminalNo: "",
        updatetime: "",
        username: "",
        vehicleId: ""
      };
    },
    // renewChange(val) {
    //   if (val) {
    //     this.autoauth = 1;
    //   } else {
    //     this.autoauth = 0;
    //   }
    // },
    changeCoin(val) {
      var coniForm = [];
      if (this.terminalType === "GPRS-部标") {
        coniForm = this.GPRSLength.filter(p => p.month === val * 1);
        this.coin = coniForm[0].coin;
      } else {
        coniForm = this.GFLength.filter(p => p.month === val * 1);
        this.coin = coniForm[0].coin;
      }
    },
    initVehiclePhotos() {
      let arr = [];
      for (let i = 0; i < 6; i++) {
        let obj = {
          sort: i + 1,
          uuid: "",
          path: "",
          file: "",
          type: [
            "车身照片",
            "车辆行驶证",
            "车辆登记证",
            "准运证开关",
            "货箱照片",
            "其他"
          ][i]
        };
        arr.push(obj);
      }
      return arr;
    }
  }
};
</script>
<style lang="scss" scoped>
.header-title {
  background: #4b7bca;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
}

.vehicle-info-content {
  height: 650px;
  font-size: 14px;
  overflow: auto;
  .content {
    overflow: auto;
    padding: 0 0 30px 0;
    .basic,
    .install,
    .terminal,
    .owner {
      margin-bottom: 20px;
      > .title {
        //   border-bottom: 1px solid #e7e7e7;
        padding: 10px 10px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          width: 5px;
          height: 16px;
          background-color: #4b7bca;
          border-radius: 3px;
        }
        &::before {
          content: '';
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          left: 80px;
          border-bottom: 1px solid #666;
        }
      }
      > .form {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 20px;
        .item {
          position: relative;
          width: 33%;
          margin: 6px 0;
          .label {
            margin-bottom: 5px;
          }
          .required::before {
            position: absolute;
            left: -10px;
            top: -5px;
            content: '*';
            color: red;
            font-weight: bold;
            font-size: 20px;
          }
          .info {
            width: 70%;
          }
          .car-group-dropdown {
            & > div:first-child {
              width: 100%;
            }
          }
        }
      }
    }
    .terminal {
      .terminal-info {
        display: flex;
        .info-item {
          width: 30%;
          padding: 10px;
          border-radius: 5px;
          background-color: #fff;
          border: 1px solid #d7e2f3;
          margin-right: 30px;
          box-shadow: 0px 2px 6px 1px rgba(66, 120, 201, 0.18);
          &:last-child {
            margin-right: 0;
          }
          .title {
            padding: 0 0 5px 0;
            border-bottom: 1px solid #e7e7e7;
            position: relative;
            .host {
              position: absolute;
              right: 0;
              font-size: 12px;
              background-image: linear-gradient(#a5caff, #6ba8ff);
              padding: 2px 6px;
              border-radius: 0 5px 0 5px;
              color: #fff;
            }
          }
          .form {
            .item {
              display: flex;
              align-items: center;
              margin-top: 5px;
              font-size: 12px;
              position: relative;
              .label {
                min-width: 80px;
                text-align: right;
              }
              .required::before {
                position: absolute;
                left: 10px;
                top: 2px;
                content: '*';
                color: red;
                font-weight: bold;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    z-index: 55;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e8eaec;
    background: #fff;
    & > :first-child {
      margin-right: 5px;
      width: 80px;
    }
    & > :last-child {
      margin-left: 5px;
      width: 80px;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-dialog__header {
  padding: 0;
  position: relative;
  .el-dialog__headerbtn {
    top: 50%;
    transform: translateY(-50%);
    .el-dialog__close {
      color: #fff;
      font-size: 20px;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 10px;
  overflow: hidden;
}
</style>
