/**
 * 写入文本至操作系统剪贴板
 */
export async function copyText(text) {

  const res = await navigator.permissions.query({ name: 'clipboard-write' });

  if (res.state === 'granted') {
    return navigator.clipboard.writeText(text)
      .then(() => true).catch(err => false);
  }

  copyText2(text);
}

/**
 * 解析系统剪贴板的文本内容返回Promse<String>
 */
export async function pasteText() {
  const res = await navigator.permissions.query({ name: 'clipboard-read' });
  if (res.state === 'granted' || res.state === 'prompt') {
    return navigator.clipboard.readText(text).then(text => text);
  }
  return Promise.reject(res);
}

/**
 * 拷贝
 */
function copyText2(text) {

  const dom = document.createElement('input');
  dom.value = text;
  document.body.appendChild(dom);
  dom.select();
  document.execCommand('copy');

  document.body.removeChild(dom);
};
