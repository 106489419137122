<template>
  <Dialog
    :value="dialogVisible"
    width="500px"
    height="250px"
    :submit="submitOk"
    @close="$emit('input',$event)"
  >
    <template #title>设置回传</template>

    <div v-if="vehicle" class="mainCls">
      <el-row>
        <el-col :span="10">
          <label>车牌:</label>
          <label>{{vehicle.plate}}</label>
        </el-col>
        <el-col :span="14">
          <label>终端号:</label>
          <label>{{vehicle.terminalNo}}</label>
        </el-col>
      </el-row>
      <!-- ACC开 -->
      <el-row>
        <el-col :span="8" :push="2">
          <el-checkbox v-model="accOnObj.enabled">ACC开</el-checkbox>
        </el-col>
        <el-col :span="9">
          <el-input-number
            v-model="accOnObj.value"
            :disabled="!accOnObj.enabled"
            :min="3"
            :max="65535"
            :step="1"
            controls-position="right"
          ></el-input-number>
        </el-col>
        <el-col :span="7">(秒, 3 ~ 65535)</el-col>
      </el-row>

      <!-- ACC关 -->
      <el-row>
        <el-col :span="8" :push="2">
          <el-checkbox v-model="accOffObj.enabled">ACC关</el-checkbox>
        </el-col>
        <el-col :span="9">
          <el-input-number
            v-model="accOffObj.value"
            :disabled="!accOffObj.enabled"
            :min="3"
            :max="65535"
            :step="1"
            controls-position="right"
          ></el-input-number>
        </el-col>
        <el-col :span="7">(秒, 3 ~ 65535)</el-col>
      </el-row>
      <!-- ACC延迟关闭 -->
      <el-row>
        <el-col :span="9" :push="2">
          <el-checkbox v-model="accDelayOffObj.enabled">ACC延迟关闭</el-checkbox>
        </el-col>
        <el-col :span="8">
          <el-input-number
            v-model="accDelayOffObj.value"
            :disabled="!accDelayOffObj.enabled"
            :min="3"
            :max="65535"
            :step="1"
            controls-position="right"
          ></el-input-number>
        </el-col>
        <el-col :span="7">(秒, 3 ~ 65535)</el-col>
      </el-row>

      <!-- 位置回传 GF-1078, GB-1078 -->
      <el-row>
        <el-col :span="8" :push="2">
          <el-checkbox
            :disabled="!(['GF-1078', 'GB-1078'].includes(vehicle.terminalType))"
            v-model="positionObj.enabled"
          >位置回传</el-checkbox>
        </el-col>
        <el-col :span="9">
          <el-input-number
            :disabled="!positionObj.enabled"
            v-model="positionObj.value"
            :min="3"
            :max="65535"
            :step="1"
            controls-position="right"
          ></el-input-number>
        </el-col>
        <el-col :span="7">(秒, 3 ~ 65535)</el-col>
      </el-row>
    </div>
  </Dialog>
</template>

<script>

/**
 * 设置回传组件
 */

import Dialog from '@/components/Dialog';
import {
  commandDown
} from '@/api/live-monitor-api';
import { searchPostBac } from '@/api/getRule';

export default {
  name: 'CmdReturnInterval',
  components: {
    Dialog,
  },
  model: {
    prop: 'dialogVisible',
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    /**
   * 车辆对象
   * {
   * plate: 车牌, 
   * terminalNo: 终端号, 
   * vehicleId: 车辆Id, 
   * goupId: 车组Id
   * terminalType: 终端类型
   * }
   */
    vehicle: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      // ACC开的状态下, 回传时间
      accOnObj: {
        enabled: true, // 启用
        value: 30, // 回传时间, 秒
        acc: 0,
      },
      // ACC关的状态下, 回传时间
      accOffObj: {
        enabled: true, // 启用
        value: 180, // 回传时间, 秒
        acc: 1,
      },
      accDelayOffObj: {
        enabled: true, // 启用
        value: 180, // 回传时间, 秒
        acc: 3,
      },
      // 位置回传
      positionObj: {
        enabled: false,
        value: 3, // 回传时间, 秒
        acc: 2,
      }
    };
  },
  mounted() {
    this.init({
      platform: 'PC',
      vehicleId: this.vehicle.vehicleId
    })
  },
  methods: {
    init(obj) {
      searchPostBac(obj).then(res=>{
        if (res.flag) {
          this.accOnObj.value = res.obj.ACC_start || 30;
          this.accOffObj.value = res.obj.ACC_close || 180;
          this.accDelayOffObj.value = res.obj.ACC_delay || 180;
          this.positionObj.value = res.obj.Location_return || 3;
        }
      })
    },
    /**
     * 提交
     */
    async submitOk() {

      const {
        vehicle,
        accOnObj,
        accOffObj,
        accDelayOffObj,
        positionObj,
      } = this;

      if (!vehicle) return this.$message.warning('车辆信息为空');

      if (accOnObj.enabled) {
        this.sendIntervalCmd(accOnObj.acc, accOnObj.value);
      }

      if (accOffObj.enabled) {
        this.sendIntervalCmd(accOffObj.acc, accOffObj.value);
      }

      if (accDelayOffObj.enabled) {
        this.sendIntervalCmd(accDelayOffObj.acc, accDelayOffObj.value);
      }

      if (positionObj.enabled) {
        this.sendIntervalCmd(positionObj.acc, positionObj.value);
      }


      this.$message.info('命令已下发，结果请查看指令查询报表.');

      this.close();

      return true;
    },
    close() {
      this.$emit('input', false);
    },

    sendIntervalCmd(acc, value, cmd = 1) {
      const { vehicle } = this;
      commandDown({
        vehicleId: vehicle.vehicleId,
        terminalNo: vehicle.terminalNo,
        cmd,
        commandMsg: `${acc}|${value}`,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.mainCls {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.el-row {
  margin: 10px 10px 0;
  display: flex;
  align-items: center;
}
</style>