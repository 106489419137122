/**
 * 获取产品类型Iconfont图标名称
 */


/**
 * @param {string} type0 - 大分类 产品类型 machineryProductType
 * @param {string} type1 - 小分类 设备类型 machineryEquipmentType
 */
export function getProductTypeIconName(type0, type1) {

  // 挖掘机械 > 履带挖掘机
  if (type0 === 'excavating_machinery' && type1 === '0') {
    // 图标: 1.1挖掘机
    return 'icon-a-11wajueji';
  }

  // 挖掘机械 > 挖掘装载机
  if (type0 === 'excavating_machinery' && type1 === '4') {
    // 图标: 2.1装卸机
    return 'icon-a-21zhuangxieji';
  }

  // 起重机械 > 塔式起重机
  if (type0 === 'hoisting_machinery' && type1 === '4') {
    // 图标: 3.2塔式起重机
    return 'icon-a-32tashiqizhongji';
  }

  // 起重机械 > 汽车起重机
  if (type0 === 'hoisting_machinery' && type1 === '0') {
    // 图标: 3.3汽车起重机
    return 'icon-a-33qicheqizhongji';
  }

  // 起重机械 > 履带起重机
  if (type0 === 'hoisting_machinery' && type1 === '2') {
    // 图标: 3.5履带起重机
    return 'icon-a-35lvdaiqizhongji';
  }

  // 工业车辆 > 牵引车
  if (type0 === 'industrial_vehicles' && type1 === '3') {
    // 图标: 4.1牵引车
    return 'icon-a-41qianyinche';
  }

  // 压实机械  > 双钢轮压路机
  if (type0 === 'compaction_machinery' && type1 === '1') {
    // 图标: 5.1压路机
    return 'icon-a-51yaluji';
  }

  // 压实机械 > 轮胎压路机
  if (type0 === 'compaction_machinery' && type1 === '2') {
    // 图标: 5.2轮胎压路机
    return 'icon-a-52luntaiyaluji';
  }

  // 筑养路机械  > 沥青摊铺机
  if (type0 === 'road_building_machinery' && type1 === '0') {
    // 图标: 6.1沥青砼摊铺机
    return 'icon-a-61liqingtongtanpuji';
  }

  // 矿山机械 > 鄂式破碎机
  if (type0 === 'mining_machinery' && type1 === '4') {
    // 图标: 6.2破碎机
    return 'icon-a-62posuiji';
  }

  // 桩工机械  > 液压打桩机
  if (type0 === 'piling_machinery' && type1 === '0') {
    // 图标: 7.1打桩机
    return 'icon-a-71dazhuangji';
  }

  // 桩工机械 > 旋挖钻
  if (type0 === 'piling_machinery' && type1 === '1') {
    // 图标: 7.2旋挖钻
    return 'icon-a-72xuanwazuan';
  }

  // 混泥土机械 > 拖泵
  if (type0 === 'concrete_machinery' && type1 === '1') {
    // 图标: 8.2混凝土泵车
    return 'icon-a-82hunningtubengche';
  }

  // 混泥土机械 > 
  if (type0 === 'concrete_machinery' && type1 === '3') {
    // 图标: 8.1混凝土搅拌运输车
    return 'icon-a-81hunningtujiaobanyunshuche';
  }

  // 高空作业机械 > 曲臂式高空作业平台
  if (type0 === 'aerial_work_machinery' && type1 === '2') {
    // 图标: 9.2自行式高空作业平
    return 'icon-a-92zihangshigaokongzuoyepingtai';
  }

  // 高空作业机械 > 剪叉式高空作业平台
  if (type0 === 'aerial_work_machinery' && type1 === '1') {
    // 图标: 9.3剪叉式高空作业车
    return 'icon-a-93jianchashigaokongzuoyeche';
  }

  // 默认图标: 1.1挖掘机
  return 'icon-a-11wajueji';
}